import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import OrgProfileAside from "./OrgProfileAside";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import orgsApis from "../../../api/master/org";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Flex, Spin } from "antd";
interface SelectOptions {
  label: string;
  value: string;
}
const OrgContactInformation = () => {
  const [submitBtnClick, setSubmitBtnClick] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

    useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState<any>();
  const [formData, setFormData] = useState({
    name: "",
    adminEmail: "",
    phoneNumber: "",
    websiteURL: "",
    country: "",
    state: "",
    city: "",
    district: "",
    pincode: "",
    addressLine1: "",
    addressLine2: "",
  });
  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      adminEmail: "",
      phoneNumber: "",
      websiteURL: "",
      country: "",
      state: "",
      city: "",
      district: "",
      pincode: "",
      addressLine1: "",
      addressLine2: "",
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const [modal, setModal] = useState(false);
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [districtOptionsRegistered, setDistrictOptionsRegistered] = useState<SelectOptions[]>([]);

  const selectedCountry = watch("country");
  const selectedRegisteredState = watch("RegisteredState");
  const selectedState = watch("state");
  const selectedDistrict = watch("district");
  const selectedRegisteredDistrict = watch("RegisteredDistrict");

  const openEditModal = () => {
    setModal(true);
  };

  const onSubmit = async (formdata: any) => {
    setSubmitBtnClick(true);
    const updatedData = {
      ...data,
      ...formdata,
    };
    if (selectedState) {
      updatedData.companyAddress.state = Number(formdata.state);
    } else {
      updatedData.companyAddress.state = Number(
        updatedData.companyAddress.state.stateId
      );
    }
    if (selectedDistrict) {
      updatedData.companyAddress.district = Number(formdata.district);
    } else {
      updatedData.companyAddress.district = Number(
        updatedData.companyAddress.district.districtId
      );
    }
    console.log("updatedData", updatedData);
    if (updatedData.companyAddress.country) {
      updatedData.companyAddress.country = Number(
        updatedData.companyAddress.country.countryId
      );
    }

    if (updatedData?.addressLine1) {
      updatedData.companyAddress.addressLine1 = formdata.addressLine1;
    }
    if (updatedData?.addressLine2) {
      updatedData.companyAddress.addressLine2 = formdata.addressLine2;
    }

    if (updatedData.registeredAddress.country) {
      updatedData.registeredAddress.country = Number(
        updatedData.registeredAddress.country.countryId
      );
    }
    if (selectedRegisteredState) {
      updatedData.registeredAddress.state = Number(
        formdata.selectedRegisteredDistrict
      );
    } else {
      updatedData.registeredAddress.state = Number(
        updatedData.registeredAddress.state.stateId
      );
    }
    if (selectedRegisteredDistrict) {
      updatedData.registeredAddress.district = Number(
        formdata.RegisteredDistrict
      );
    } else {
      updatedData.registeredAddress.district = Number(
        updatedData.registeredAddress.district.districtId
      );
    }
    if (updatedData.category) {
      updatedData.category = parseInt(updatedData.category);
    }

    if (updatedData?.registeredAddressLine1) {
      updatedData.registeredAddress.addressLine1 =
        formdata.registeredAddressLine1;
    }
    if (updatedData?.registeredAddressLine2) {
      updatedData.registeredAddress.addressLine2 =
        formdata.registeredAddressLine1;
    }
    updatedData.companyAddress.city = formdata.city;
    updatedData.registeredAddress.RegisteredCity = formdata.RegisteredCity;
    console.log("updatedData", updatedData);
    delete updatedData.orgID;
    delete updatedData.country;
    delete updatedData.state;
    delete updatedData.city;
    delete updatedData.district;
    delete updatedData.pincode;
    delete updatedData.docs;
    delete updatedData?.addressLine1;
    delete updatedData?.addressLine2;

    delete updatedData.RegisteredCountry;
    delete updatedData.RegisteredState;
    delete updatedData.RegisteredDistrict;
    delete updatedData.RegisteredCity;
    delete updatedData.RegisteredPincode;
    delete updatedData?.registeredAddressLine1;
    delete updatedData?.registeredAddressLine2;

    console.log("updatedData", updatedData);
    // return;
    const submitedData: any = new FormData();
    submitedData.append("files", JSON.stringify([]));
    submitedData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    submitedData.append("orgData", JSON.stringify(updatedData));
    try {
      const { status, message } = await orgsApis.updateOrgById(
        data.orgID,
        submitedData
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchOrgData();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
    setSubmitBtnClick(false);
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const fetchOrgData = async () => {
    try {
      const { data, status, message } = await orgsApis.fetchOrg();
      console.log("status", data);
      if (status) {
        const newData = data;
        setData(newData);
        setFormData({
          name: newData.name,
          adminEmail: newData.adminEmail,
          phoneNumber: newData.phoneNumber,
          websiteURL: newData.websiteURL,
          country: newData.companyAddress.country.countryName,
          state: newData.companyAddress.state.stateName,
          city: newData.companyAddress.city,
          district: newData.companyAddress.district.districtName,
          pincode: newData.companyAddress.pincode,
          addressLine1: newData.companyAddress.addressLine1,
          addressLine2: newData.companyAddress.addressLine2,
        });
        setValue("name", newData.name);
        setValue("adminEmail", newData.adminEmail);
        setValue("phoneNumber", newData.phoneNumber);
        setValue("websiteURL", newData.websiteURL);
        setValue("name", newData.name);
        setValue("addressLine1", newData.companyAddress.addressLine1);
        setValue("addressLine2", newData.companyAddress.addressLine2);
        setValue(
          "country",
          newData.companyAddress.country.countryId.toString()
        );
        setValue("state", newData.companyAddress.state.stateId.toString());
        setValue("city", newData.companyAddress.city);
        setValue(
          "district",
          newData.companyAddress.district.districtId.toString()
        );
        setValue(
          "RegisteredCountry",
          newData.registeredAddress.country.countryId.toString()
        );
        setValue("pincode", newData.companyAddress.pincode);
        setValue(
          "registeredAddressLine1",
          newData.registeredAddress.addressLine1
        );
        setValue(
          "registeredAddressLine2",
          newData.registeredAddress.addressLine2
        );
        setValue(
          "RegisteredCountry",
          newData.registeredAddress.country.countryId.toString()
        );
        setValue(
          "RegisteredState",
          newData.registeredAddress.state.stateId.toString()
        );
        setValue("RegisteredCity", newData.registeredAddress.city);
        setValue(
          "RegisteredDistrict",
          newData.registeredAddress.district.districtId.toString()
        );
        setValue("RegisteredPincode", newData.registeredAddress.pincode);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const getCountries = async () => {
    try {
      const _countries = await orgsApis.getCountrys();
      console.log(_countries);
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
          if (selectedCountry) {
            setValue("country", selectedCountry.value);
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await orgsApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any, districtsID?: any) => {
    try {
      const districts = await orgsApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
          if (districtsID) {
            setValue("district", districtsID);
          } else {
            setValue("district", districts.data[0].districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getDistrictsRegistered = async (stateID: any, districtsID?: any) => {
    try {
      const districts = await orgsApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptionsRegistered(transformedData);
          if (districtsID) {
            setValue("RegisteredDistrict", districtsID);
          } else {
            setValue(
              "RegisteredDistrict",
              districts.data[0].districtId.toString()
            );
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    console.log("selectedRegisteredState", selectedRegisteredState);
    if (selectedRegisteredState) {
      getDistrictsRegistered(selectedRegisteredState);
    }
  }, [selectedRegisteredState]);

  useEffect(() => {
    if (selectedState) {
      getDistricts(selectedState);
    }
  }, [selectedState]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    getCountries();
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    fetchOrgData();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <OrgProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Contact Information</BlockTitle>
                    <BlockDes>
                      <p>
                        Basic Contact Details...
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    {allowedActions?.edit?<Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => openEditModal()}
                    >
                      <Icon name="edit-alt-fill"></Icon>
                      <span>Edit</span>
                    </Button>:<></>}
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                {isLoading === true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : (
                  <div className="nk-data data-list">
                    <div className="data-head">
                      <h6 className="overline-title">Basics</h6>
                    </div>

                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Admin Name</span>
                        <span className="data-value">{data.name}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Admin Email</span>
                        <span className="data-value">
                          <a
                            href="#"
                            style={{ pointerEvents: "auto", cursor: "default" }}
                          >
                            {data.adminEmail}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Phone Number</span>
                        <span className="data-value">{data.phoneNumber}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Website</span>
                        <span className="data-value">{data.websiteURL}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Company Address</span>
                        <span className="data-value">
                          {data.companyAddress.addressLine1}, <br />
                          {data.companyAddress.addressLine2 && (
                            <>
                              {data.companyAddress.addressLine2}, <br />
                            </>
                          )}
                          {data.companyAddress.city},{" "}
                          {data?.companyAddress.state.stateName},{" "}
                          {data.companyAddress.district.districtName},{" "}
                          {data.companyAddress.country.countryName},{" "}
                          {data.companyAddress.pincode}
                        </span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Registered Address</span>
                        <span className="data-value">
                          {data.registeredAddress.addressLine1}, <br />
                          {data.registeredAddress.addressLine2 && (
                            <>
                              {data.registeredAddress.addressLine2}, <br />
                            </>
                          )}
                          {data.registeredAddress.city},{" "}
                          {data.registeredAddress.state.stateName},{" "}
                          {data.registeredAddress.district.districtName},{" "}
                          {data.registeredAddress.country.countryName},{" "}
                          {data.registeredAddress.pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Block>

              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="xl"
                toggle={() => setModal(false)}
              >
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-2">
                        <h5 className="title">Edit Contact Details</h5>
                        <div className="tab-content">
                          <div className="p-2">
                            <Row className="gy-2">
                              <Col md="4">
                                <FormInput
                                  name="name"
                                  label="Admin Name"
                                  // defaultValue={data?.adminEmail}
                                  type="text"
                                  placeholder="Enter Admin Name"
                                />
                              </Col>
                              <Col md="4">
                                <FormInput
                                  name="adminEmail"
                                  label="Admin Email"
                                  disabled
                                  required={true}
                                  // defaultValue={data?.adminEmail}
                                  type="email"
                                  placeholder="Enter Email address"
                                  pattern={
                                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                                  }
                                />
                              </Col>
                              <Col md="4">
                                <FormInput
                                  name="phoneNumber"
                                  label="Phone Number"
                                  // defaultValue={data?.phoneNumber?.substring(
                                  //   data?.phoneNumber?.length - 10
                                  // )}
                                  // defaultValue={data?.phoneNumber}
                                  required={true}
                                  type="number"
                                  placeholder="Enter Admin Phone Number"
                                  pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                                />
                              </Col>

                              <Col md="4">
                                <FormInput
                                  name="websiteURL"
                                  label="Website"
                                  defaultValue={data?.websiteURL}
                                  type="text"
                                  placeholder="Enter Website URL"
                                  pattern={
                                    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/
                                  }
                                />
                              </Col>
                            </Row>

                            <Row className="gy-4 mt-1">
                              <h6 className="title"> Company Address</h6>
                            </Row>
                            <Row className="gy-2 mt-1">
                              <Col lg="4">
                                <FormInput
                                  name="addressLine1"
                                  label="Address Line 1"
                                  required={true}
                                  type="text"
                                  placeholder="Street Address Line 1"
                                />
                              </Col>
                              <Col lg="4">
                                <FormInput
                                  name="addressLine2"
                                  label="Address Line 2"
                                  required={true}
                                  type="text"
                                  placeholder="Street Address Line 2"
                                />
                              </Col>

                              <Col md="4">
                                <FormSelect
                                  name="country"
                                  label="Country"
                                  options={countries}
                                  required={true}
                                  placeholder="-Select-"
                                  // onChange={(e) => {
                                  //   fetchStatesByCountry(e);
                                  // }}
                                />
                              </Col>
                              <Col md="4">
                                <FormSelect
                                  name="state"
                                  label="State"
                                  options={states}
                                  required={true}
                                  placeholder="-Select-"
                                  // onChange={(ev) => onStateChange(ev)}
                                  // defaultValue={selectedState}
                                />
                              </Col>
                              <Col lg="4">
                                <FormSelect
                                  name="district"
                                  label="District"
                                  options={districtOptions}
                                  required={true}
                                  placeholder="Select District"
                                />
                              </Col>
                              <Col md="4">
                                <FormInput
                                  name="city"
                                  label="City"
                                  placeholder="Enter City Name"
                                  type="text"
                                />
                              </Col>

                              <Col md="4">
                                <FormInput
                                  name="pincode"
                                  label="Zip/Pincode"
                                  // defaultValue={data?.pincode}
                                  required={true}
                                  type="number"
                                  placeholder="Enter Zip or pin code"
                                />
                              </Col>
                            </Row>
                            <Row className="gy-4 mt-1">
                              <h6 className="title"> Registered Address</h6>
                            </Row>
                            {/* <Row className="gy-4 mt-1">
                              <Col md="12" className="ml-5">
                                <div className="form-group">
                                  <FormCheckbox
                                    size={"medium"}
                                    name="registeredAddressSameAsCompany"
                                    optionLabel="Registered address same as company address"
                                    defaultChecked={sameAsCompany}
                                    onChange={() =>
                                      setSameAsCompany(!sameAsCompany)
                                    }
                                  />
                                </div>
                              </Col>
                            </Row> */}
                            {/* {!sameAsCompany && ( */}
                              <>
                                <Row className="gy-2 mt-1">
                                  <Col lg="4">
                                    <FormInput
                                      name="registeredAddressLine1"
                                      label="Registered Address Line 1"
                                      required={true}
                                      type="text"
                                      placeholder="Registered Street Address Line 1"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <FormInput
                                      name="registeredAddressLine2"
                                      label="Registered Address Line 2"
                                      required={true}
                                      type="text"
                                      placeholder="Registered Street Address Line 2"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <FormSelect
                                      name="RegisteredCountry"
                                      label="Registered Country"
                                      options={countries}
                                      required={true}
                                      placeholder="-Select-"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg="4">
                                    <FormSelect
                                      name="RegisteredState"
                                      label="Registered State"
                                      options={states}
                                      required={true}
                                      placeholder="-Select-"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <FormSelect
                                      name="RegisteredDistrict"
                                      label="Registered District"
                                      options={districtOptionsRegistered}
                                      required={true}
                                      placeholder="-Select-"
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <FormInput
                                      name="RegisteredCity"
                                      label="Registered City/Town"
                                      required={true}
                                      type="text"
                                      placeholder="Enter City/Town"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg="4">
                                    <FormInput
                                      name="RegisteredPincode"
                                      label="Registered Zip/Pincode"
                                      required={true}
                                      type="text"
                                      placeholder="Enter Billing Zip or pin code"
                                    />
                                  </Col>
                                </Row>
                              </>
                            {/* )} */}

                            <Row className="gy-4 mt-4">
                              <Col size="12">
                                {submitBtnClick ? (
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                      <li>
                                        <Button color="primary" type="button">
                                          <Spinner size="sm" color="light" />
                                        </Button>
                                      </li>
                                    </ul>
                                  ) : (
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button color="primary" size="lg">
                                      Update
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setModal(false);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default OrgContactInformation;
