import React, { useState, useEffect, useRef } from "react";
import { Select, Empty, Tooltip } from "antd";
import * as echarts from "echarts";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { format } from "date-fns";
import budgetApis from "../../../api/master/budget";
import { InfoCircleOutlined } from "@ant-design/icons"; // Import Info Icon from Ant Design

const { Option } = Select;

const currency = "INR";

interface BudgetSpendAnalysisProps {
  budgetVsSpendData: any;
}

interface CategoryData {
  categoryID: string;
  categoryName: string;
  budget: number;
  timePeriod: "daily" | "monthly";
  dates: string[];
  budgetAllocated: number[];
  amountSpent: number[];
}

const BudgetVsSpendChart: React.FC<any> = ({ budgetVsSpendData }: any) => {
  const [selectedCategoryID, setSelectedCategoryID] = useState<any>();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [summaryData, setSummaryData] = useState<any>({
    TotalBudgetValue: 0,
    TotalBudgetPercentage: 0,
    ActualSpendValue: 0,
    ActualSpendPercentage: 0,
    RemainingBudgetValue: 0,
    RemainingBudgetPercentage: 0,
    SpendVariationValue: 0,
    SpendVariationPercentage: 0,
  });
  const [data, setData] = useState<any>([]);
  // console.log("budgetVsSpendData", budgetVsSpendData)

  useEffect(() => {
    if (budgetVsSpendData) {
      // Safeguard to ensure data is complete
      if (
        budgetVsSpendData.totalBudget?.value !== undefined &&
        budgetVsSpendData.monthOnMonthSavings?.data
      ) {
        setSummaryData({
          TotalBudgetValue: budgetVsSpendData?.totalBudget?.value || "0.00",
          TotalBudgetPercentage:
            budgetVsSpendData?.totalBudget?.percentage || "0.00",
          ActualSpendValue: budgetVsSpendData?.actualSpend?.value || "0.00",
          ActualSpendPercentage:
            budgetVsSpendData?.actualSpend?.percentage || "0.00",
          RemainingBudgetValue:
            budgetVsSpendData?.remainingBudget?.value || "0.00",
          RemainingBudgetPercentage:
            budgetVsSpendData?.remainingBudget?.percentage || "0.00",
          SpendVariationValue:
            budgetVsSpendData?.spendVariation?.value || "0.00",
          SpendVariationPercentage:
            budgetVsSpendData?.spendVariation?.percentage || "0.00",
        });

        const graphData = budgetVsSpendData.monthOnMonthSavings.data;
        const dates = graphData?.map(
          (item: any) => `${item.month} ${item.year}`
        );
        const budgetAllocated = graphData?.map(
          (item: any) => item.totalBudgetValue || "0.00"
        );
        const amountSpent = graphData?.map(
          (item: any) => item.totalSpendValue || "0.00"
        );

        const formattedData: any = {
          categoryID:
            budgetVsSpendData?.monthOnMonthSavings?.categoryId?.toString() ||
            "unknown",
          budget: budgetVsSpendData?.totalBudget?.value || "0.00",
          timePeriod: "monthly",
          dates: dates || [],
          budgetAllocated: budgetAllocated || [],
          amountSpent: amountSpent || [],
        };
        console.log("Formatted Data:", formattedData);
        setData([formattedData]);
      } else {
        console.log("Data incomplete, waiting for update...");
      }
    }
  }, [budgetVsSpendData]);

  useEffect(() => {
    if (data.length > 0 && data[0]?.dates?.length > 0) {
      initializeChart(data[0]); // Initialize the chart when data is available
    } else {
      disposeChart(); // Dispose chart if data is invalid
    }
  }, [data]); // Add `data` as a dependency

  const initializeChart = (selectedData: any) => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }

      const option = {
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            const budget = params[0].value;
            const spent = params[1].value;
            console.log("spent", spent);
            const percentage =
              spent !== 0.0 && ((spent / budget) * 100)?.toFixed(2);
            const color = spent > budget ? "red" : "green";
            return `
                            <div>
                                <strong>${params[0].name}</strong><br/>
                                Budget: ${formatCurrency(budget, currency)}<br/>
                                Spent: ${formatCurrency(
                                  spent,
                                  currency
                                )} <span style="color:${color};">(${
              percentage === "NaN" ? "0.00" : percentage
            }%)</span>
                            </div>
                        `;
          },
        },
        grid: {
          left: "3%",
          right: "0",
          bottom: "15%", // Increase this value to add more space for the legend
          top: "8%",
          containLabel: true,
        },
        legend: {
          data: ["Budget Allocated", "Amount Spent"],
          orient: "horizontal",
          bottom: "5%", // Adjust this to create space between the legend and axis labels
          right: "0",
        },
        xAxis: {
          type: "category",
          data: selectedData.dates,
          name: "Date",
          nameLocation: "middle",

          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 20,
          },
        },
        yAxis: {
          type: "value",
          name: `Amount (${currency})`,
          nameLocation: "middle",

          axisLabel: {
            formatter: (value: number) => formatCurrency2(value, currency),
          },
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 50,
          },
        },
        // grid: {
        //   left: "3%",
        //   right: "0",
        //   bottom: "10%",
        //   top: "8%",
        //   containLabel: true,
        // },
        series: [
          {
            name: "Budget Allocated",
            type: "line",
            data: selectedData.budgetAllocated,
            lineStyle: {
              color: "#3BAEA3",
            },
            smooth: true,
            areaStyle: {
              color: "rgba(59, 174, 163, 0.3)", // Light teal fill color with transparency
            },
          },
          {
            name: "Amount Spent",
            type: "line",
            data: selectedData.amountSpent,
            lineStyle: {
              color: "#5470C6",
            },
            smooth: true,
          },
        ],
      };

      chartInstance.current.setOption(option);
    }
  };

  const selectedData = data;

  const disposeChart = () => {
    if (chartInstance.current) {
      chartInstance.current.dispose();
      chartInstance.current = null;
    }
  };

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Budget vs Spend Analysis</h6>
          <p>
            Comparison of budget allocation and spending over the selected
            period.
          </p>
        </div>
      </div>
      <div className="analytic-data-group analytic-ov-group g-3 mt-1">
        <div className="analytic-data analytic-ov-data blackFont">
          <div className="title">Allocated Budget</div>
          <div className="amount">
            {formatCurrency2(Number(summaryData?.TotalBudgetValue), currency)}
            <Tooltip
              title={formatCurrency(summaryData?.TotalBudgetValue, "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </div>
          <div className="change up">
            <em className="icon ni ni-arrow-long-up"></em>
            {summaryData?.TotalBudgetPercentage}
          </div>
        </div>
        <div className="analytic-data analytic-ov-data">
          <div className="title">Actual Spend</div>
          <div className="amount">
            {formatCurrency2(Number(summaryData?.ActualSpendValue), currency)}
            <Tooltip
              title={formatCurrency(summaryData?.ActualSpendValue, "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </div>
          <div className="change up">
            <em className="icon ni ni-arrow-long-up"></em>
            {summaryData?.ActualSpendPercentage}
          </div>
        </div>
        <div className="analytic-data analytic-ov-data">
          <div className="title">Remaining Budget</div>
          <div className="amount">
            {formatCurrency2(
              Number(summaryData?.RemainingBudgetValue),
              currency
            )}
            <Tooltip
              title={formatCurrency(summaryData?.RemainingBudgetValue, "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </div>
          <div className="change up">
            <em className="icon ni ni-arrow-long-up"></em>
            {summaryData?.RemainingBudgetPercentage}
          </div>
        </div>
        <div className="analytic-data analytic-ov-data">
          <div className="title">Spend Overrun %</div>
          <div className="amount">
            {/* {summaryData?.SpendVariationPercentage}% */}
            {summaryData?.SpendVariationPercentage !== undefined &&
            summaryData?.SpendVariationPercentage !== null
              ? `${Number(summaryData?.SpendVariationPercentage).toFixed(2)}%`
              : "0%"}
          </div>
          <div
            className={`${
              Number(summaryData?.SpendVariationValue) !== 0
                ? "change down"
                : ""
            }`}
          >
            {Number(summaryData?.SpendVariationValue) !== 0 && (
              <em className="icon ni ni-arrow-long-down"></em>
            )}
            {formatCurrency2(
              Number(summaryData?.SpendVariationValue),
              currency
            )}
          </div>
        </div>
      </div>
      {data &&
      data.length > 0 &&
      data[0]?.amountSpent?.length > 0 &&
      data[0]?.budgetAllocated?.length > 0 ? (
        <>
          {
            // Check if there are non-zero values in amountSpent or budgetAllocated
            data[0].amountSpent.some((val: string) => val !== "0.00") ||
            data[0].budgetAllocated.some((val: string) => val !== "0.00") ? (
              <div
                ref={chartRef}
                id="budget-spend-chart"
                style={{ width: "100%", height: "350px" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "300px",
                }}
              >
                <Empty description="No budget details found for this category" />
              </div>
            )
          }
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No budget details found for this category" />
        </div>
      )}
    </div>
  );
};

export default BudgetVsSpendChart;
