import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function PurchaseByCategoryReport() {
  const navigate = useNavigate();
//    {
//                 "categoryId": 45,
//                 "materialCategoryName": "Astral Pipes",
//                 "poCount": "1",
//                 "uniqueSuppliers": "1",
//                 "amountWithOutTax": "10.10L",
//                 "amountWithTax": "11.31L"
//             }
  const columns: any = [
    // {
    //   title: "Category Id",
    //   dataIndex: "categoryId",
    //   key: "categoryId",
    //   render: (text: any) => <span>{text}</span>,
    // },
    {
      title: "Material Category Name",
      dataIndex: "materialCategoryName",
      key: "materialCategoryName",
      fixed: "left",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "PO Count",
      dataIndex: "poCount",
      key: "poCount",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/order-details-by-category-report/${record?.categoryId}`);
            }}
          >
            {" "}{text}{" "}
          </span>
        );
      },
    },
    {
      title: "Unique Suppliers",
      dataIndex: "uniqueSuppliers",
      key: "uniqueSuppliers",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/invoice-details-by-category-report/${record?.categoryId}`);
            }}
          >
            {" "}{text}{" "}
          </span>
        );
      },
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Category Report"
      moduleKey="purchaseByCategoryReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX:1000
      }}
    />
  );
}

export default PurchaseByCategoryReport;