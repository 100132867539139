import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function PurchaseBySiteReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      fixed: "left",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "PO Count",
      dataIndex: "poCount",
      key: "poCount",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/order-details-by-site-report/${record?.siteId}`);
            }}
          >
            {" "}{text}{" "}
          </span>
        );
      },
    },
    {
      title: "Unique Suppliers",
      dataIndex: "uniqueSuppliers",
      key: "uniqueSuppliers",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/invoice-details-by-site-report/${record?.siteId}`);
            }}
          >
            {" "}{text}{" "}
          </span>
        );
      },
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
pageTitle="Purchase By Site Report"
      moduleKey="purchaseBySiteReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX:1000
      }}
    />
  );
}

export default PurchaseBySiteReport;