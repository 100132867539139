import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, Tooltip, Legend } from "chart.js";
import {
  planPurchase,
  planPurchaseSet2,
  planPurchaseSet3,
  planPurchaseSet4,
  totalBalance,
  totalDeposit,
  totalWithdraw,
} from "./InvestData";
import dayjs from "dayjs";
import { formatCurrency } from "../../../../utils/Utils";

Chart.register(CategoryScale, LinearScale, BarElement, PointElement, Tooltip, Legend);

export const DepositBarChart = ({ totalPurchaseOrdersData }) => {
  console.log("totalPurchaseOrdersData", totalPurchaseOrdersData);
  const [data, setData] = useState();

  useEffect(() => {
    if (Array.isArray(totalPurchaseOrdersData) && totalPurchaseOrdersData.length > 0) {
      formatData(totalPurchaseOrdersData);
    }
  }, [totalPurchaseOrdersData]);

  const formatData = (data) => {
    console.log("data", data);
    let chartData = {
      labels: data.map(item => dayjs(item.date).format('MMM D')),
      dataUnit: "USD",
      stacked: true,
      datasets: [
        {
          label: "Total Values",
          backgroundColor: data.map((_, idx) =>
            idx === data.length - 1
              ? "rgb(101, 118, 255)"
              : "rgba(101, 118, 255, 0.2)"
          ),
          barPercentage: 0.7,
          categoryPercentage: 0.7,
          data: data.map((item) => Number(item.value)), // Convert value to number
          // label: data.map(item => dayjs(item.date).format('MMM D')), // Use date for labels
        },
      ],
    }
    console.log("chartData", chartData)
    setData(chartData);
  };

  // Conditional rendering if data is not ready
  if (!Array.isArray(totalPurchaseOrdersData) || totalPurchaseOrdersData.length === 0) {
    return <p>Loading chart data...</p>;
  }

  return (
    <>
      {data && (
        <>
          <Bar
            className="iv-data-chart"
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  displayColors: false,
                  backgroundColor: "#eff6ff",
                  titleFont: {
                    size: "11px",
                  },
                  titleColor: "#6783b8",
                  titleMarginBottom: 4,
                  bodyColor: "#9eaecf",
                  bodyFont: {
                    size: "10px",
                  },
                  bodySpacing: 3,
                  padding: 8,
                  footerMarginTop: 0,
                  callbacks: {
                    title: function (tooltipItems) {
                      // Get the date corresponding to the hovered bar
                      const index = tooltipItems[0]?.dataIndex; // Get the index of the hovered bar
                      return dayjs(totalPurchaseOrdersData[index].date).format('MMM D'); // Return formatted date for title
                    },
                    label: function (tooltipItem) {
                      // Show the value along with the label
                      const value = tooltipItem.raw; // Get the raw value
                      return `Value: ${formatCurrency(value,"INR")}`; // Return formatted value for label
                    },
                  },
                },
              },
              maintainAspectRatio: false,
              scales: {
                y: {
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
          />
        </>
      )}
    </>
  );
}

export const BalanceBarChart = ({ balanceBarChartData }) => {
  console.log("balanceBarChartData", balanceBarChartData);
  const [data, setData] = useState();

  useEffect(() => {
    if (Array.isArray(balanceBarChartData) && balanceBarChartData.length > 0) {
      formatData(balanceBarChartData);
    }
  }, [balanceBarChartData]);

  const formatData = (data) => {
    console.log("data", data);
    let chartData = {
      labels: data.map(item => dayjs(item.date).format('MMM D')),
      dataUnit: "USD",
      stacked: true,
      datasets: [
        {
          label: "Total Values",
          backgroundColor: data.map((_, idx) =>
            idx === data.length - 1
              ? "rgb(101, 118, 255)"
              : "rgba(101, 118, 255, 0.2)"
          ),
          barPercentage: 0.7,
          categoryPercentage: 0.7,
          data: data.map((item) => Number(item.value)), // Convert value to number
        },
      ],
    }
    console.log("chartData", chartData)
    setData(chartData);
  };

  // Conditional rendering if data is not ready
  if (!Array.isArray(balanceBarChartData) || balanceBarChartData.length === 0) {
    return <p>Loading chart data...</p>;
  }



  return (
    <>
      {data && (
        <>
          <Bar
            className="iv-data-chart"
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  displayColors: false,
                  backgroundColor: "#eff6ff",
                  titleFont: {
                    size: '11px',
                  },
                  titleColor: "#6783b8",
                  titleMarginBottom: 4,
                  bodyColor: "#9eaecf",
                  bodyFont: {
                    size: '10px',
                  },
                  bodySpacing: 3,
                  padding: 8,
                  footerMarginTop: 0,
                  callbacks: {
                    title: function (tooltipItems) {
                      // Get the date corresponding to the hovered bar
                      const index = tooltipItems[0]?.dataIndex; // Get the index of the hovered bar
                      return dayjs(balanceBarChartData[index].date).format('MMM D'); // Return formatted date for title
                    },
                    label: function (tooltipItem) {
                      // Show the value along with the label
                      const value = tooltipItem.raw; // Get the raw value
                      return `Value:  ${formatCurrency(value,"INR")}`; // Return formatted value for label
                    },
                  },
                },
              },
              maintainAspectRatio: false,
              scales: {
                y: {
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
          ></Bar>
        </>
      )
      }
    </>
  )
}

export const WithdrawBarChart = ({ withdrawBarChartData }) => {
  console.log("withdrawBarChartData", withdrawBarChartData);
  const [data, setData] = useState();

  useEffect(() => {
    if (Array.isArray(withdrawBarChartData) && withdrawBarChartData.length > 0) {
      formatData(withdrawBarChartData);
    }
  }, [withdrawBarChartData]);

  const formatData = (data) => {
    console.log("data", data);
    let chartData = {
      labels: data.map((item) => {
        const dateObj = new Date(item.date); // Convert string to Date object
        const day = String(dateObj.getDate()).padStart(2, '0'); // Get day as two digits
        const month = dateObj.toLocaleString("default", { month: "short" }); // Get abbreviated month
        return `${day} ${month}`; // Return formatted date
      }),
      dataUnit: "USD",
      stacked: true,
      datasets: [
        {
          label: "Total Values",
          backgroundColor: data.map((_, idx) =>
            idx === data.length - 1
              ? "rgb(101, 118, 255)"
              : "rgba(101, 118, 255, 0.2)"
          ),
          barPercentage: 0.7,
          categoryPercentage: 0.7,
          data: data.map((item) => Number(item.value)), // Convert value to number
        },
      ],
    }
    console.log("chartData", chartData)
    setData(chartData);
  };

  // Conditional rendering if data is not ready
  if (!Array.isArray(withdrawBarChartData) || withdrawBarChartData.length === 0) {
    return <p>Loading chart data...</p>;
  }
  return (
    <>
      {data && (
        <>
          <Bar
            className="iv-data-chart"
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  displayColors: false,
                  backgroundColor: "#eff6ff",
                  titleFont: {
                    size: '11px',
                  },
                  titleColor: "#6783b8",
                  titleMarginBottom: 4,
                  bodyColor: "#9eaecf",
                  bodyFont: {
                    size: '10px',
                  },
                  bodySpacing: 3,
                  padding: 8,
                  footerMarginTop: 0,
                  callbacks: {
                    title: function (tooltipItems) {
                      // Get the date corresponding to the hovered bar
                      const index = tooltipItems[0]?.dataIndex; // Get the index of the hovered bar
                      return dayjs(withdrawBarChartData[index].date).format('MMM D'); // Return formatted date for title
                    },
                    label: function (tooltipItem) {
                      // Show the value along with the label
                      const value = tooltipItem.raw; // Get the raw value
                      return `Value: ${formatCurrency(value,"INR")}`; // Return formatted value for label
                    },
                  },
                },
              },
              maintainAspectRatio: false,
              scales: {
                y: {
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
          ></Bar>
        </>
      )}
    </>
  );
};

export const PurchasePlanChart = ({ set }) => {
  const [data, setData] = useState(planPurchase);
  useEffect(() => {
    let object;
    if (set === "7") {
      object = planPurchaseSet2;
    } else if (set === "15") {
      object = planPurchaseSet3;
    } else {
      object = planPurchaseSet4;
    }
    setData(object);
  }, [set]);

  return (
    <Bar
      className="iv-plan-purchase"
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: '11px',
            },
            titleColor: "#6783b8",
            titleMarginBottom: 4,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: '10px',
            },
            bodySpacing: 3,
            padding: 8,
            footerMarginTop: 0,
            callbacks: {
              title: function () {
                return false;
              },
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            ticks: {
              beginAtZero: true,
            },
          },
          x: {
            display: false,
          },
        },
      }}
    ></Bar>
  );
};
