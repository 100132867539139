import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function OrderDetailsBySiteReport() {
  const { id } = useParams();
  const columns: any = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "left",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width:200,
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string) => Number(quantity).toFixed(2),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>₹ {Number(text).toFixed(2)}</span>
        </div>
      ), 
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle="Order Details By Site Report"
      moduleKey="orderDetailsBySiteReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-site-reports-list"
      extra={{
        id:id,
        tableScrollX:1700
      }}
    />
  );
}

export default OrderDetailsBySiteReport;