import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function PurchaseByMaterialDetailReport() {
  const { id } = useParams();
  
  const columns: any = [
    {
      title:"Invoice Id",
      dataIndex:"invoiceId",
      key:"invoiceId",
      // width:200,
      fixed:"left"
    },
    {
      title:"Invoice Date",
      dataIndex:"invoiceDate",
      key:"invoiceDate",
      // width:130,
      render: (text: any, record: any) => (
        <div>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </div>
      ),
    },
    {
      title:"Supplier Name",
      dataIndex:"supplierName",
      key:"supplierName",
      width:250,
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Material Detail Report"
      moduleKey="purchaseByMaterialDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-material-reports-list"
      extra={{
        id:id
      }}
    />
  );
}

export default PurchaseByMaterialDetailReport;