import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import OrgProfileAside from "./OrgProfileAside";
import FormInput from "../../form-components/InputComponent";
import orgsApis from "../../../api/master/org";
import { FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Flex, Spin } from "antd";
interface SelectOptions {
  label: string;
  value: string;
}
const OrgBankInformation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [submitBtnClick, setSubmitBtnClick] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState<any>();
  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo]: any = useState("");
  const [formData, setFormData] = useState({
    accountHolderName: "",
    bankaccountNumber: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
    // files:null
  });

  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      accountHolderName: "",
      bankaccountNumber: "",
      bankName: "",
      branchName: "",
      ifscCode: "",
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const [modal, setModal] = useState(false);
  const [docs, setDocs] = useState<any>([]);

  const [fileName, setFileName] = useState(""); // State to manage file name

  const fileWatch = watch("file");

  const openEditModal = () => {
    setModal(true);
  };

  const onSubmit = async (submitedFormdata: any) => {
    setSubmitBtnClick(true);
    const submitedData: any = new FormData();
    if (submitedFormdata?.files) {
      const filesData = [];
      const files = [];
      const deletedFileData: any = [];
      if (submitedFormdata.files[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "orgs",
          purpose: "cheque",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(submitedFormdata.files[0]);
        const previousDoc = docs;
        if (previousDoc) {
          // previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: previousDoc.docId,
          });
          // });
        }
      }
      submitedData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      files.forEach((file) => {
        submitedData.append(`files`, file);
      });
    } else {
      submitedData.append("files", JSON.stringify([]));
      submitedData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete submitedFormdata.files;
    console.log(submitedFormdata);
    submitedData.append("orgData", JSON.stringify(submitedFormdata));
    try {
      const { status, message } = await orgsApis.updateOrgById(
        data.orgID,
        submitedData
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchOrgData();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
    setSubmitBtnClick(false);
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const fetchOrgData = async () => {
    try {
      const { data, status, message } = await orgsApis.fetchOrg();
      console.log("status", data);
      if (status) {
        const newData = data;
        const { docs } = newData;
        setDocs(
          docs.find((document: any) => document.moduleFileType == "cheque")
        );
        setData(newData);
        setFormData({
          accountHolderName: newData.accountHolderName,
          bankaccountNumber: newData.bankaccountNumber,
          bankName: newData.bankName,
          branchName: newData.branchName,
          ifscCode: newData.ifscCode,
        });
        setValue("accountHolderName", newData.accountHolderName);
        setValue("bankaccountNumber", newData.bankaccountNumber);
        setValue("bankName", newData.bankName);
        setValue("branchName", newData.branchName);
        setValue("ifscCode", newData.ifscCode);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    fetchOrgData();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Org - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <OrgProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Bank Details</BlockTitle>
                    <BlockDes>
                      <p>Basic Bank Details...</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    {allowedActions?.edit ? (
                      <Button
                        outline
                        className="bg-white d-none d-sm-inline-flex"
                        style={{ marginRight: "10px" }}
                        onClick={() => openEditModal()}
                      >
                        <Icon name="edit-alt-fill"></Icon>
                        <span>Edit</span>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                {isLoading === true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : (
                  <div className="nk-data data-list">
                    <div className="data-head">
                      <h6 className="overline-title">Basics</h6>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Company Account Name</span>
                        <span className="data-value">
                          {data.accountHolderName}
                        </span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Bank Account Number</span>
                        <span className="data-value">
                          {data.bankaccountNumber}
                        </span>
                      </div>
                    </div>

                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Bank Name</span>
                        <span className="data-value">{data.bankName}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Branch Name</span>
                        <span className="data-value">{data.branchName}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">IFSC Code</span>
                        <span className="data-value">{data.ifscCode}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <div className="data-label">Cancelled Cheque</div>
                        <div className="data-value">
                          <a
                            href={docs?.filePath}
                            target="_blank"
                            style={{
                              pointerEvents: "auto",
                              cursor: "default",
                            }}
                          >
                            {docs?.fileName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Block>

              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="lg"
                toggle={() => setModal(false)}
              >
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-2">
                        <h5 className="title">Edit Bank Details</h5>
                        <div className="p-2">
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="accountHolderName"
                                  // disabled={action === "edit"}
                                  label="Company Account Name"
                                  required={true}
                                  placeholder="Enter Company Account Name"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="bankaccountNumber"
                                  // disabled={action === "edit"}
                                  label="Account Number"
                                  required={true}
                                  placeholder="Enter Account Number"
                                  type="number"
                                  // pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="bankName"
                                  // disabled={action === "edit"}
                                  label="Bank Name"
                                  required={true}
                                  placeholder="Enter Bank Name"
                                />
                              </div>
                            </Col>

                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="branchName"
                                  // disabled={action === "edit"}
                                  label="Branch"
                                  required={true}
                                  placeholder="Enter Branch"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="ifscCode"
                                  // disabled={action === "edit"}
                                  label="IFSC Code"
                                  required={true}
                                  placeholder="Enter IFSC Code"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="files"
                                  label="Upload Cancelled Cheque"
                                  // required={true}
                                  type="file"
                                  placeholder="Upload Cancelled Cheque"
                                />
                                {fileWatch && <div>{fileWatch[0]?.name}</div>}
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-3"></div>
                          <Row className="gy-4">
                            <Col size="12">
                              {submitBtnClick ? (
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button color="primary" type="button">
                                      <Spinner size="sm" color="light" />
                                    </Button>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button color="primary" size="lg">
                                      Update
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setModal(false);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default OrgBankInformation;
