import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function PurchaseByMaterialReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Material ID",
      dataIndex: "materialId",
      key: "materialId",
    //   width: 100,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/purchase-by-material-reports-detail-report/${text}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Average Price",
      dataIndex: "avgPrice",
      key: "avgPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Quantity Purchased",
      dataIndex: "quantityPurchased",
      key: "quantityPurchased",
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Material Report"
      moduleKey="purchaseByMaterialReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default PurchaseByMaterialReport;