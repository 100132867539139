import React, { useEffect, useState, useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import { Form } from "react-router-dom";
import Swal from "sweetalert2";
import sites from "../../../api/master/sites";
interface SelectOptions {
  label: string;
  value: string;
}
const InventoryInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
    control,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sites",
  });

  const submitForm = (data: any) => {
    props.next();
  };

  const [workingSites, setWorkingSites] = useState<any>([]);

  const fetchWorkingSites = async (selectedOptions: SelectOptions[] = []) => {
    try {
      const { data, status, message } = await sites.fetchSites();
      if (status) {
        setWorkingSites(
          data.map((item: any) => ({
            label: item.name,
            value: item.siteID?.toString(),
          }))
        );
        // if (selectedOptions.length > 0) {
        //   setValue("siteAccessibility", selectedOptions);
        // }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };
  useEffect(() => {
    fetchWorkingSites();
  },[]);
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row>
        {fields.map((field, index) => (
          <div key={field.id} className="row mb-3">
            <div className="col-md-2">
              <FormSelect
                name={`sites.${index}.siteID`}
                label="Site"
                required={true}
                options={workingSites}
                placeholder="Select Site"
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`sites.${index}.openingStock`}
                label="Stock Quantity"
                type="number"
                placeholder="Enter Stock Quantity"
                required={true}
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`sites.${index}.minimumStockLevel`}
                label="Minimum Stock Level"
                type="number"
                placeholder="Enter Minimum Stock Level"
                // required={true}
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`sites.${index}.maximumStockLevel`}
                label="Maximum Stock Level"
                type="number"
                placeholder="Enter Maximum Stock Level"
                // required={true}
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`sites.${index}.reorderPoint`}
                label="Reorder Point"
                type="number"
                placeholder="Enter Reorder Point"
                // required={true}
              />
            </div>
            <div className="col-md-2">
              <div style={{ marginTop: 30 }}>
                <button
                  type="button"
                  className="btn btn-success ms-2"
                  onClick={() =>
                    append({
                      siteID: "",
                      openingStock: "",
                      minimumStockLevel: "",
                      maximumStockLevel: "",
                      reorderPoint: "",
                    })
                  }
                >
                  +
                </button>
                &nbsp;
                {index > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => remove(index)}
                  >
                    -
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(InventoryInformation);
