import React, { useEffect, FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "../../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
} from "../../../components/Component";
import { Tabs, Descriptions, Button } from 'antd';
import type { TabsProps } from 'antd';

import Snapshot from './Snapshot';
import QuotationComparison from './QuotationComparison';
import BAFOAnalysis from './BAFOAnalysis';
import quotationApis from "../../../api/master/quotations";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";

const RFQFullView: FC = () => {
    const location = useLocation();
    const rfq = location.state?.rfq;
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>(); // Track active tab
    const [countsData, setCountsData]: any = useState();
    const [supplierQuotations, setSupplierQuotations]: any = useState<any[]>([]);
    const [initialMaterials, setInitialMaterials] = useState<any[]>([]);
    const [creationDate, setCreationDate] = useState<string | null>(null);
    const [quotationHeader, setQuotationHeader] = useState<any>({});
    console.log("RFQ", rfq)

    useEffect(() => {
        console.log("location", location.state)
        if (location.state?.from === 'qc') {
            setActiveTab('2');
        }
        else {
            setActiveTab('1');
        }
    }, [rfq]);

    const handleTabChange = (key: string) => {
        setActiveTab(key);  // Update active tab state
    };

    const handleDataUpdate = async () => {
        const data = await getSupplierQuotations();
        if (data) {
            console.log("data::::DaTSA", data, data?.quotationHeader?.createdAt);
            setCountsData(data.counts);
            setCreationDate(data?.quotationHeader?.createdAt ? FormatDate(data?.quotationHeader?.createdAt) : "N/A");
            setSupplierQuotations(data.supplierHeaders);
            setQuotationHeader(data.quotationHeader)
            const data2 = data?.materials.map((item: any, index: any) => {
                return {
                    ...item,
                    key: index + 1,
                };
            });
            setInitialMaterials(data2);
        }
    };

    useEffect(() => {
        handleDataUpdate();  // Fetch data on initial load
    }, []);

    const getSupplierQuotations = async () => {
        try {
            const { response, status } = await quotationApis.quoteComparision(rfq.quotationId);
            if (status) {
                return response.data;
            }
        } catch (error: any) {
            // handle the error
        }
    };

    if (!rfq) {
        return <div>No RFQ data available</div>;
    }
    const items: TabsProps['items'] = [
        { key: '1', label: 'Snapshot', children: <Snapshot rfq={rfq} /> },
        {
            key: '2', label: 'Quotation Comparison', children: <QuotationComparison rfq={rfq} initialMaterialsData={initialMaterials}
                supplierQuotations={supplierQuotations}
                quotationHeader={quotationHeader}
                countsData={countsData}
                onUpdate={handleDataUpdate}  // Pass callback to handle data update
            />
        },
        { key: '3', label: 'BAFO Analysis', children: <BAFOAnalysis rfq={rfq} activeTab={activeTab} /> },
    ];

    return (
        <>
            <Content>
                {/* <Card className="card-bordered"> */}
                {/* <div className="card-inner"> */}
                <div className="rfqoverview" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <BlockHead>
                        <BlockHeadContent>
                            <BlockTitle tag="h5">Quotation Details</BlockTitle>
                        </BlockHeadContent>
                    </BlockHead>
                    <Descriptions column={3} style={{ flex: 1, marginLeft: ' 10px' }}>
                        <Descriptions.Item label="RFQ ID">{rfq.rfqId}</Descriptions.Item>
                        <Descriptions.Item label="Site Name">{rfq.siteName}</Descriptions.Item>
                        <Descriptions.Item label="Created Date">{creationDate}</Descriptions.Item>
                    </Descriptions>
                    <Button type="default" onClick={() => navigate(-1)} style={{ marginLeft: 'auto' }}>
                        <em className="icon ni ni-arrow-left" style={{ marginRight: '5px' }}></em>Back
                    </Button>
                </div>
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange} // Handle tab change
                    items={items}
                    tabBarStyle={{
                        color: '#2263b3',
                        fontSize: '20px',
                    }}
                    tabBarGutter={32}
                    renderTabBar={(props, DefaultTabBar) => (
                        <DefaultTabBar {...props} style={{ marginBottom: '0' }} />
                    )}
                />
                {/* </div> */}
                {/* </Card> */}
            </Content>

        </>
    );
};

export default RFQFullView;
