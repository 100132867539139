import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Progress, Table, Select, Input, Flex, Spin, Empty } from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import quotationApis from "../../api/master/quotations";
import moment from "moment";
import Swal from "sweetalert2";

const { Option } = Select;

interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  mappingStatus: string;
}

const RFQView: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [widgetData, setWidgetData] = useState<WidgetData[]>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);
    fetchCounts();
    fetchList();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchCounts = async () => {
    try {
      const { response, status } = await quotationApis.fetchCounts();
      if (status) {
        const counts = response.data;
        const colors = ["#OO65BD", "#20639B", "#FA991C", "#F6D55C", "#OOCC99"];
        let formatData = counts.map((item: any, index: number) => ({
          title: item.title,
          count: item.count,
          progress: item.progress.toFixed(0),
          color: colors[index % colors.length],
        }));
        setWidgetData(formatData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchList = async () => {
    try {
      const { response, status, message } = await quotationApis.fetchList();
      console.log("WidgetData", widgetData);
      if (status) {
        const data = response.map((item: any, index: number) => {
          // console.log("item", item);
          let colorCode = widgetData.find((widget: any) => widget.title == item.status)?.color;
          item.quotationHeader.statusColor = colorCode;
          item.quotationHeader.mappingStatus = item.status;
          return item.quotationHeader;
        });

        console.log("data", data);
        setTableData(data);
        setFilteredData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const handleStatusFilterChange = (value: string | undefined) => {
    console.log("value", value);
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let filteredData = tableData;

    if (searchText) {
      filteredData = filteredData.filter(
        (item) =>
          item.rfqId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.quotationDescription
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      console.log("filteredData", filteredData, status);
      filteredData = filteredData.filter((item: any) => item.mappingStatus == status);
    }
    console.log("filteredData11111", filteredData);
    setFilteredData(filteredData);
  };
  const navigate = useNavigate();
  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate("/rfq-full-view", { state: { rfq } });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 200,
      sorter: (a, b) => a.rfqId.localeCompare(b.rfqId),
      align: "left",
      render: (text, record) => (
        <a onClick={() => handleRFQClick(record)} style={{ color: '#1677ff', fontWeight: "600" }}>{text}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      fixed: "left",
      width: 200,
      sorter: (a, b) =>
        a.quotationDescription.localeCompare(b.quotationDescription),
      align: "left",
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
      sorter: (a, b) => a.siteName.localeCompare(b.siteName),
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Submission Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      sorter: (a, b) => a.deadlineType.localeCompare(b.deadlineType),
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      sorter: (a, b) =>
        new Date(a.lastSubmissionDate).getTime() -
        new Date(b.lastSubmissionDate).getTime(),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 150,
      sorter: (a, b) => a.costCenterName.localeCompare(b.costCenterName),
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "mappingStatus",
      key: "mappingStatus",
      fixed: "right",
      render: (text, record) => {
        console.log("record", record.statusColor)
        return (
          <>
            <span style={{ color: record.statusColor }}>{text}</span>
          </>
        )
      },
      width: 150,
      sorter: (a: any, b: any) => a.mappingStatus.localeCompare(b.mappingStatus),
      align: "left",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record: any, index) =>
        <>
          <Button
            size="sm"
            // color=""
            onClick={() => {
              navigate(`/view/${record.quotationId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
    },
  ];

  return (
    <>
      <Head title="RFQ - List" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}

                <Block>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {widgetData.map((widget, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid #dbdfea",
                          padding: "15px",
                          borderRadius: "8px",
                          width: "calc(20% - 10px)", // Ensure widgets are 20% of the width
                          minWidth: "150px", // Ensure a minimum width for smaller screens
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "20px" }}>
                          <h6 style={{ margin: 0 }}>{widget.title}</h6>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Progress
                              type="circle"
                              percent={widget.progress}
                              width={50}
                              strokeColor={widget.color}
                              format={(percent) => `${percent}%`}
                            />
                          </div>
                          <div style={{ flex: 1, textAlign: "center" }}>
                            <p style={{ fontSize: "24px", margin: 0 }}>
                              {widget.count}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Block>
                {
                  filteredData.length > 0 ? (
                    <>
                      <Block>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <div>
                            <span>Filter by Status: </span>
                            <Select
                              style={{ width: 200 }}
                              onChange={handleStatusFilterChange}
                              value={statusFilter || "ALL"}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <div
                                    style={{
                                      padding: "8px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>Search by Status: </span>
                                    <SearchOutlined />
                                  </div>
                                </>
                              )}
                            >
                              <Option value="ALL">All</Option>
                              {
                                Array.from(new Set(tableData.map((item) => item.mappingStatus))).map((status) => (
                                  <Option key={status} value={status}>{status}</Option>
                                ))
                              }
                              {/* <Option value="Pending">Pending</Option>
                              <Option value="Requested">Requested</Option>
                              <Option value="Quoted">Quoted</Option>
                              <Option value="Negotiated">Negotiated</Option>
                              <Option value="Awarded">Awarded</Option>
                              <Option value="Rejected">Rejected</Option>
                              <Option value="Regretted">Regretted</Option> */}
                              {/* <Option value="Under QC Approval">Under QC Approval</Option>
                              <Option value="QC Approved">QC Approved</Option> */}
                            </Select>
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Input
                              placeholder="Search by Site Name &amp; RFQ ID"
                              value={searchText}
                              onChange={handleSearchChange}
                              style={{ width: 400 }}
                              prefix={<SearchOutlined />}
                            />
                          </div>
                        </div>
                      </Block>
                      <Row>
                        <Col>
                          <div
                            style={{ width: screenSize - 320, overflow: "auto" }}
                            id="customTable"
                          >
                            <Table
                              columns={columns}
                              dataSource={filteredData}
                              pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "30", "50"],
                              }}
                              bordered
                              size="middle"
                              scroll={{ x: 1400 }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <Empty description="No Quotations" />
                      </div>
                    </>
                  )
                }
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default RFQView;
