import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function PaymentsAgingSummaryReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 200,
    },

    {
      title: "Outstanding Amount",
      dataIndex: "totalOutstandingAmount",
      key: "totalOutstandingAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "totalOverdueAmount",
      key: "totalOverdueAmount",

      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`//${record?.supplierId}`);
            }}
          >
          {text}{" ("}{record?.overduePercentage}{"%)"}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Payments Aging Summary Report"
      moduleKey="paymentsAgingSummaryReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        aging: true,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default PaymentsAgingSummaryReport;