import React from 'react'
import { useParams } from 'react-router';
import ViewOrderDetails from '../../purchase-orders/ViewOrderDetails';

function PurchaseOrderPreview() {
  let { orderNumber }: any = useParams();
  return (
    <ViewOrderDetails orderNumber={orderNumber} previousPath="/purchase-order-reports-list" extra={{
      hideAside: true,
      reduceTableWidth: 130
    }} />
  )
}

export default PurchaseOrderPreview;