import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import Swal from "sweetalert2";
import supplierApis from "../../../api/master/supplier";
import adminOrgApis from "../../../api/master/adminOrg";
import materialsApis from "../../../api/master/materials";
import miscService from "../../../api/misc/misc";
import misc from "../../../api/misc/dropDowns";
interface SelectOptions {
  label: string;
  value: any;
}
const ProductInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const [supplierOptions, setSupplierOptions] = useState([]);
  useEffect(() => {
    // fetchSuppliersDropdown();
    fetchUOMOPtions();
    fetchSecondaryUOMOPtions();
    // fetchSupplierCategory();
    getContructionsMaterialTypes();
  }, []);

  const [industryTypes, setIndustryTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [secondaryUomOptions, setSecondaryUomOptions] = useState<
    SelectOptions[]
  >([]);
  const [disableCategory, setDisableCategory] = useState(true);
  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await supplierApis.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await misc.fetchAllUomByType("primary");
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSecondaryUOMOPtions = async () => {
    try {
      const { data, status, message } = await misc.fetchAllUomByType(
        "secondary"
      );
      if (status) {
        setSecondaryUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSuppliersDropdown = async () => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplier();
      if (status) {
        // Assuming `data` is an array of suppliers
        const formattedData = data.map((supplier: any) => ({
          label: supplier.supplierName,
          value: supplier.supplierID,
        }));
        setSupplierOptions(formattedData);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to fetch suppliers", "error");
    }
  };
  const submitForm = (data: any) => {
    console.log("data", data);
    props.next();
  };
  const preventInvalidChars = (event: any) => {
    if (["e", "E", "-", "+"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const findCategoryByMaterialTypeId = async (materialTypeId: any) => {
    console.log(materialTypeId);
    // return;
    try {
      const { data, status, message } =
        await materialsApis.findCategoryByMaterialType(materialTypeId);
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
        setDisableCategory(false);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-3">
          <FormInput
            name="productCode"
            label="Material Code"
            maxLength={20}
            type="text"
            pattern={/^[a-zA-Z0-9\s]*$/}
            placeholder="Enter Material Code"
            required={true}
          />
        </div>
        <div className="col-md-3">
          <FormSelect
            name="materialType"
            label="Material Type"
            required={true}
            options={industryTypes}
            onChange={(e: any) => {
              findCategoryByMaterialTypeId(e);
            }}
          />
        </div>
        <div className="col-md-3">
          <FormSelect
            name="category"
            disabled={disableCategory}
            label="Material Category"
            required={true}
            options={categories}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="productName"
            label="Material Name"
            type="text"
            placeholder="Enter Material Name"
            required={true}
          />
        </div>

        <div className="col-md-3">
          <FormSelect
            name="unitOfMeasure"
            label="Primary Unit of Measure"
            required={true}
            options={uomOptions}
          />
        </div>
        <div className="col-md-3">
          <FormSelect
            name="secondaryUnitOfMeasure"
            label="Secondary Unit of Measure"
            required={true}
            options={secondaryUomOptions}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="uomConversionRatio"
            label="UOM Conversion Ratio"
            required={true}
            type="text"
            placeholder="Enter UOM Conversion Ratio"
            defaultValue="1:"
            onInput={(e: React.FormEvent<HTMLInputElement>) => {
              let inputValue = e.currentTarget.value;
              
              // Ensure "1:" prefix is always present and only numbers follow it
              if (!inputValue.startsWith("1:")) {
                inputValue = "1:";
              }
        
              // Remove any non-numeric characters after the prefix
              e.currentTarget.value = "1:" + inputValue.slice(2).replace(/[^0-9]/g, "");
            }}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="specification"
            label="Specification"
            type="text"
            required={true}
            placeholder="Enter Specification"
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="productLife"
            label="Material Life (in days)"
            type="number"
            onKeyDown={preventInvalidChars}
            pattern={/^[0-9\s]*$/}
            placeholder="Enter Material Life"
          />
        </div>
        {/* <div className="col-md-3">
          <FormSelect
            name="supplierID"
            label="Preferred Suppliers"
            required={true}
            options={supplierOptions}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="averageLeadDays"
            label="Delivery Lead Days (AVG)"
            type="number"
            placeholder="Enter Delivery Lead Days"
            required={true}
          />
        </div> */}
        <div className="col-md-12">
          <FormTextArea
            name="description"
            label="Description"
            placeholder="Enter Description"
            required={true}
          />
        </div>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(ProductInformation);
