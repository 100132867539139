import makeApiCall from "../master/axiosIndex";
const paymentDashboardApis = {
    fetchPaymentAnalytics: (sites:any,contractors:any,fromDate: string, toDate: string): Promise<any> => {
        return makeApiCall<any>({
            method: "get",
            url: `/dashboard/paymentAnalytics/fetchPaymentAnalytics?sites=${sites}&contractors=${contractors}&fromDate=${fromDate}&toDate=${toDate}`,
        })
    },
    fetchImmediatePayments: (sites:any,contractors:any): Promise<any> => {
       return makeApiCall<any>({
           method: "get",
           url: `/dashboard/paymentAnalytics/fetchImmediatePayments?sites=${sites}&contractors=${contractors}`,
       }) 
    },
    fetchOverDueAgingAnalysis: (sties:any,contractors:any,fromDate: string, toDate: string): Promise<any> => {
        return makeApiCall<any>({
            method: "get",
            url: `/dashboard/paymentAnalytics/fetchOverDueAgingAnalysis?sites=${sties}&contractors=${contractors}&fromDate=${fromDate}&toDate=${toDate}`,
        })
    }
    ,fetchMonthOnMonthOverDueAgingAnalysis: (sties:any,contractors:any, toDate: string): Promise<any> => {
        return makeApiCall<any>({
            method: "get",
            url: `/dashboard/paymentAnalytics/monthOnMonthOverDueAgingAnalysis?sites=${sties}&contractors=${contractors}&date=${toDate}`,
        })
    },
    fetchMonthOnMonthOverDueAgingSupplierWiseList: (sties:any,contractors:any, fromDate: string, toDate: string): Promise<any> => {
        return makeApiCall<any>({
            method: "get",
            url: `/dashboard/paymentAnalytics/monthOnMonthOverDueAgingSupplierWiseList?sites=${sties}&contractors=${contractors}&fromDate=${fromDate}&toDate=${toDate}`,
        })
    },

}

export default paymentDashboardApis