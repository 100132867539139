import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Col, Row } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
} from "../../components/Component";
import OverdueAgingAnalysis from "./analytics/OverdueAgingAnalysis";
import ImmediatePayments from "./analytics/ImmediatePayments";
import { Select, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import PaymentWidgets from "./analytics/PaymentWidgets";
import MonthOnMonthOverdue from "./analytics/MonthOnMonthOverdue";
import DispatchAside from "./paymentsNav";
import paymentDashboardApis from "../../api/payments/paymentDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import contractorsApis from "../../api/master/contractors";
const { Option } = Select;
const { RangePicker } = DatePicker;

const PaymentsAnalytics: FC = () => {
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [widgetData, setWidgetData] = useState({
    totalPurchases: 0,
    purchasesMonthValue: 0,
    purchasesWeekValue: 0,
    accountsPayables: 0,
    payablesMonthValue: 0,
    payablesWeekValue: 0,
    overdue: 0,
    overdueMonthValue: 0,
    overdueWeekValue: 0,
    currency: "INR",
  });
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [overdueData, setOverdueData] = useState({});
  const [monthOnMonthOverDue, setMonthOnMonthOverDue] = useState([]);
  const [
    monthOnMonthOverDueAgingSupplierWishList,
    setMonthOnMonthOverDueAgingSupplierWishList,
  ] = useState<any>([]);
  const [paymentData, setPaymentData] = useState([]);
  const [site, setSite] = useState("");
  const [contractor, setContractor] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const [contractorOptions, setContractorOptions] = useState<any>([]);
  const [dateRange, setDateRange] = useState("Quarter to Date");
  const [customDateRange, setCustomDateRange] = useState<any>();

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites: any = [];
        let _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setSite(allSites.join(","));
        if (_siteOptions?.length > 1) {
          _siteOptions = [
            {
              label: "ALL",
              value: allSites?.join(","),
            },
            ..._siteOptions,
          ];
        }
        setSiteOptions(_siteOptions);
      }
    }
  }, [currentUser]);

  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId + "",
      });
      if (status) {
        let allContractors: any = [];
        let _contractorOptions: any = [];
        data.forEach((item: any) => {
          allContractors.push(item?.contractorID?.toString());
          _contractorOptions.push({
            label: item?.companyName,
            value: item?.contractorID?.toString(),
          });
        });

        setContractor(allContractors?.join(","));
        if (_contractorOptions.length > 1) {
          _contractorOptions = [
            {
              label: "ALL",
              value: allContractors?.join(","),
            },
            ..._contractorOptions,
          ];
        }
        setContractorOptions(_contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchOverDueAgingAnalysis = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await paymentDashboardApis.fetchOverDueAgingAnalysis(
        site,
        contractor,
        from,
        to
      );
      const { data, status } = res;
      if (status) {
        const _data = data[0];
        const _overdueData = {
          "<10 Days": Number(_data?.lessThan10Days),
          "11-20 Days": Number(_data["11To20Days"]),
          "21-30 Days": Number(_data["21To30Days"]),
          "31-45 Days": Number(_data["31To45Days"]),
          ">45 Days": Number(_data["moreThan45Days"]),
          currency: "INR",
        };
        setOverdueData(_overdueData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchMonthOnMonthOverDueAgingAnalysis = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res =
        await paymentDashboardApis.fetchMonthOnMonthOverDueAgingAnalysis(
          site,
          contractor,
          from
        );
      const { data, status } = res;
      if (status) {
        setMonthOnMonthOverDue(
          data?.map((item: any) => {
            return {
              ...item,
              percentage: Number(item.percentage).toFixed(),
            };
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMonthOnMonthOverDueAgingSupplierWiseList = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res =
        await paymentDashboardApis.fetchMonthOnMonthOverDueAgingSupplierWiseList(
          site,
          contractor,
          from,
          to
        );
      const { data, status } = res;
      if (status) {
        setMonthOnMonthOverDueAgingSupplierWishList(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPaymentAnalytics = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await paymentDashboardApis.fetchPaymentAnalytics(
        site,
        contractor,
        from,
        to
      );
      const { data, status } = res;
      if (status) {
        const {
          totalPurchases,
          purchasesMonthValue,
          purchasesWeekValue,
          accountsPayables,
          payablesMonthValue,
          payablesWeekValue,
          overdue,
          overdueMonthValue,
          overdueWeekValue,
          currency,
        } = data;
        setWidgetData({
          totalPurchases: Number(totalPurchases),
          purchasesMonthValue: Number(purchasesMonthValue),
          purchasesWeekValue: Number(purchasesWeekValue),
          accountsPayables: Number(accountsPayables),
          payablesMonthValue: Number(payablesMonthValue),
          payablesWeekValue: Number(payablesWeekValue),
          overdue: Number(overdue),
          overdueMonthValue: Number(overdueMonthValue),
          overdueWeekValue: Number(overdueWeekValue),
          currency,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchImmediatePayments = async () => {
    try {
      const res = await paymentDashboardApis.fetchImmediatePayments(
        site,
        contractor
      );
      const { data, status } = res;
      if (status) {
        setPaymentData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const convertToDates = (dateType: string) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const handleSiteChange = (value: string) => {
    setSite(value);
  };
  useEffect(() => {
    if (site) {
      fetchPaymentAnalytics();
      fetchOverDueAgingAnalysis();
      fetchMonthOnMonthOverDueAgingAnalysis();
      fetchImmediatePayments();
      fetchMonthOnMonthOverDueAgingSupplierWiseList();
    }
  }, [site, contractor, dateRange, customDateRange]);

  useEffect(() => {
    if (site) {
      fetchContractorList(site);
    }
  }, [site]);

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  const handleCustomDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([dates[0], dates[1]]);
    } else {
      setCustomDateRange(null);
    }
  };

  return (
    <>
      <Head title="Payment Analytics" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Payments Analytics</BlockTitle>
                    <BlockDes>
                      <p>Integrated Dashboard for Payments</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="d-flex align-items-center"></BlockHeadContent>
                </BlockBetween>
                <div className="d-flex align-items-center flex-wrap">
                  <div style={{ marginRight: 16, marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Site:
                    </label>
                    <Select
                      defaultValue={
                        siteOptions?.length==1 ? siteOptions[0].label: "ALL"
                      }
                      style={{ width: 150 }}
                      onChange={handleSiteChange}
                    >
                      {siteOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div>

                  <div style={{ marginRight: 16, marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Contractor:
                    </label>
                    <Select
                      defaultValue={
                        contractorOptions?.length==1 ? contractorOptions[0].label: "ALL"
                      }
                      style={{ width: 150 }}
                      onChange={(value: any) => {
                        setContractor(value);
                      }}
                    >
                      {contractorOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div>

                  <div style={{ marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Date Range:
                    </label>
                    <Select
                      defaultValue="Quarter to Date"
                      style={{ width: 200, marginRight: 16 }}
                      onChange={handleDateRangeChange}
                    >
                      <Option value="Month to Date">Month to Date</Option>
                      <Option value="Quarter to Date">Quarter to Date</Option>
                      <Option value="Year to Date">Year to Date</Option>
                      <Option value="Custom Date">Custom Date</Option>
                    </Select>
                    {dateRange === "Custom Date" && (
                      <RangePicker
                        value={
                          customDateRange
                            ? [
                                dayjs(customDateRange[0].toDate()),
                                dayjs(customDateRange[1].toDate()),
                              ]
                            : null
                        }
                        onChange={(dates, dateStrings) =>
                          handleCustomDateChange(dates)
                        }
                        style={{ width: 300 }}
                      />
                    )}
                  </div>
                </div>
              </BlockHead>
              <Block>
                <Row className="g-gs mt-2"></Row>
                <Row className="g-gs">
                  <Col lg="12">
                    <PaymentWidgets
                      totalPurchases={widgetData?.totalPurchases}
                      purchasesMonthValue={widgetData?.purchasesMonthValue}
                      purchasesWeekValue={widgetData?.purchasesWeekValue}
                      accountsPayables={widgetData?.accountsPayables}
                      payablesMonthValue={widgetData?.payablesMonthValue}
                      payablesWeekValue={widgetData?.payablesWeekValue}
                      overdue={widgetData?.overdue}
                      overdueMonthValue={widgetData?.overdueMonthValue}
                      overdueWeekValue={widgetData?.overdueWeekValue}
                      currency={widgetData?.currency || "INR"}
                    />
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <OverdueAgingAnalysis overdueData={overdueData} />
                    </PreviewAltCard>
                  </Col>
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <ImmediatePayments paymentData={paymentData} />
                    </PreviewAltCard>
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="12">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <MonthOnMonthOverdue
                        overdueData={monthOnMonthOverDue}
                        monthOnMonthOverDueAgingSupplierWishList={
                          monthOnMonthOverDueAgingSupplierWishList
                        }
                      />
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default PaymentsAnalytics;
