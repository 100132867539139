import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function SupplierPaymentsInvoiceReport() {
  const { id } = useParams();
  const columns: any = [
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      fixed: "left",
      width:200,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width:100,
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width:230,
    },
    {
      title:"GRN Date",
      dataIndex:"grnDate",
      key: "grnDate",
      width:100,
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    }, 
    {
      title:"Payment Due Date",
      dataIndex:"paymentDueDate",
      key: "paymentDueDate",
      // width:100,
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    }, 
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Payment Days",
      dataIndex: "paymentDays",
      key: "paymentDays",
      width:150,
      // render: (paymentDays: any) => <span>{paymentDays} days</span>,
      render: (text: any, record: any) => {
        //  "flag": "overDue",
        const isHighlighted = record?.flag=="overDue";
        return (
          <div
            className="align-left"
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // Highlight with light red if quantities differ
              color: isHighlighted ? "white" : "inherit", // Optionally change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {Number(text) + " days"}
          </div>
        );
      },
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Supplier Payments Invoice Report"
      moduleKey="supplierPaymentsInvoiceReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/supplier-payments-reports-list"
      extra={{
        id: id,
        tableScrollX:1000
      }}
    />
  );
}

export default SupplierPaymentsInvoiceReport;