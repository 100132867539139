import React from 'react';
import { Avatar, Tooltip } from 'antd';
import {
    CheckCircleOutlined,
    FormOutlined,
    AuditOutlined,
    FileProtectOutlined,
    ShoppingCartOutlined,
    SafetyCertificateOutlined,
    FileDoneOutlined,
    RocketOutlined,
    CarOutlined,
    DollarCircleOutlined,
} from '@ant-design/icons';
import { colors } from '../../utils/Utils';
import * as Icons from '@ant-design/icons';

const renderIcon = (iconName: string) => {
    // Log the raw iconName for debugging
    console.log("Raw iconName:", iconName);
  
    // Clean the icon name string by removing '<', '/', and '>'
    const cleanedIconName = iconName.replace(/[<>/]/g, '').trim();
  
    // Log the cleaned iconName for debugging
    console.log("Cleaned iconName:", cleanedIconName);
  
    // Ensure cleanedIconName is treated as a string key in Icons
    const IconComponent:any = Icons[cleanedIconName as keyof typeof Icons];
  
    // Log the IconComponent for debugging
    console.log("IconComponent:", IconComponent);
  
    // Return the icon component if it exists, otherwise return null
    return IconComponent ? <IconComponent /> : null;
  };

const P2PWidget: React.FC = (procureToPayCycleData:any) => {
    console.log("procureToPayCycleData", procureToPayCycleData.procureToPayCycleData);
    let timelineData = procureToPayCycleData.procureToPayCycleData

    return (
        <div>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Procure to Pay Cycle</h6>
                    <p>Tracking the end-to-end procurement process</p>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {timelineData?.map((item:any, index:any) => (
                        <React.Fragment key={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    width: 100,
                                    height: '100px',
                                }}
                            >
                                <Avatar
                                    style={{
                                        backgroundColor: colors[index],
                                        color: '#fff',
                                        marginBottom: '8px',
                                        width: 50,
                                        height: 50,
                                    }}
                                    icon={renderIcon(item.icon)}
                                />
                                <div>{item.title}</div>
                            </div>
                            {index < timelineData?.length - 1 && (
                                <div style={{ flex: 1, position: 'relative', textAlign: 'center' }}>
                                    {/* avgDays positioned above the line */}
                                    <Tooltip title={item.avgDays}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-24px',
                                                width: '100%',
                                                fontSize: '12px',
                                                color: '#888',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '80px', // Adjust max width as needed
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.avgDays}
                                        </div>
                                    </Tooltip>
                                    {/* Line in the middle */}
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '2px',
                                            backgroundColor: '#E5E9F2',
                                            position: 'absolute',
                                            top: '50%', // Center the line between Avatars
                                        }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default P2PWidget;
