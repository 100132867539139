import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function InvoiceDetailsByCategoryReport() {
  const { id } = useParams();
  const columns: any = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "left",
    },
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width:200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string,record:any) => <span>{quantity} {record.quantityUom}</span>,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Credit Days",
      dataIndex: "creditDays",
      key: "creditDays",
      render: (creditDays: any) => <span>{creditDays} days</span>,
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Invoice Details By Category Report"
      moduleKey="invoiceDetailsByCategoryReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-category-reports-list"
      extra={{
        id: id,
        tableScrollX:1700
      }}
    />
  );
}

export default InvoiceDetailsByCategoryReport;
