import React, { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import {
  Progress,
  Table,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
} from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import quotationApis from "../../api/master/quotations";
import Swal from "sweetalert2";
import moment from "moment";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}
const RFQApprovals: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);
  const [form] = Form.useForm();

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);
    fetchPendingApprovalList();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const navigate = useNavigate();
  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate(`/view/${rfq.quotationId}`, {
      state: { screenStatus: "Pending RFQ Approvals" },
    });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 170,
      sorter: (a, b) => a.rfqId.localeCompare(b.rfqId),
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleRFQClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      sorter: (a, b) =>
        a.quotationDescription.localeCompare(b.quotationDescription),
      align: "left",
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 150,
      sorter: (a, b) => a.siteName.localeCompare(b.siteName),
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      sorter: (a, b) => a.deadlineType.localeCompare(b.deadlineType),
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      sorter: (a, b) =>
        new Date(a.lastSubmissionDate).getTime() -
        new Date(b.lastSubmissionDate).getTime(),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 150,
      sorter: (a, b) => a.costCenterName.localeCompare(b.costCenterName),
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      align: "left",
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   fixed: "right",
    //   width: 160, // Increase width to accommodate additional buttons
    //   render: (text: any, record: any) => (
    //     <>
    //       <Button
    //         size="sm"
    //         color="success"
    //         onClick={() => {
    //           Swal.fire({
    //             title: "Are you sure?",
    //             text: "You won't be able to revert this!",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes, Approve it!",
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               handleApprove(record.quotationId);
    //             }
    //           });
    //           // Handle approve action here

    //         }}
    //         className="mx-1" // Adds a small margin between buttons
    //       >
    //         <Icon name="check" title="Approve" />
    //       </Button>

    //       <Button
    //         size="sm"
    //         color="danger"
    //         onClick={() => {
    //           Swal.fire({
    //             title: "Are you sure?",
    //             text: "You won't be able to revert this!",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes, Reject it!",
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               handleReject(record.quotationId);
    //             } else {
    //             }
    //           });

    //         }}
    //       >
    //         <Icon name="cross" tile="Reject" />
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  const handleReject = async (id: string) => {
    setRejectRecord(id);
    setIsModalVisible(true);
    // try {
    //   const res = await quotationApis.reject(id);
    //   const { response, status, message } = res;
    //   console.log("data", res);
    //   if (status) {
    //     Swal.fire("Approved!", "Quotation has been Approved", "success");
    //     fetchPendingApprovalList();
    //   } else {
    //     Swal.fire("Failed!", message, "error");
    //   }
    // } catch (error) {
    //   Swal.fire("Failed!", "Something went wrong", "error");
    // }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Assuming `record.headerId` is used to identify the purchase order
      const payload = {
        headerId: rejectRecord,
        reason: values.reason,
      };

      // Call the API to submit the rejection
      await apiCallToRejectBudget(rejectRecord, values.reason);

      // Handle success (e.g., show a success message, refresh the table)

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      // Handle validation errors or API errors
    }
  };

  const apiCallToRejectBudget = async (quotationId: any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      const res = await quotationApis.reject(quotationId, { reason: reason });
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Approved!", "Quotation has been Rejected", "success");
        fetchPendingApprovalList();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleApprove = async (id: string) => {
    try {
      const res = await quotationApis.approve(id);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Approved!", "Quotation has been Approved", "success");
        fetchPendingApprovalList();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let filteredData = tableData;

    if (searchText) {
      filteredData = filteredData.filter(
        (item) =>
          item.rfqId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.quotationDescription
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      filteredData = filteredData.filter((item) => item.status === status);
    }

    setFilteredData(filteredData);
  };

  const fetchPendingApprovalList = async () => {
    try {
      const { response, status, message } =
        await quotationApis.fetchPendingApprovals();
      if (status) {
        const data = response.data.map((item: any) => {
          return item.quotationHeader;
        });

        console.log("data", data);
        setTableData(data);
        setFilteredData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Head title="RFQ - Approvals" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">Pending RFQ Approvals </BlockTitle>
                      <BlockDes>
                        <p>Approval/Reject RFQ</p>
                      </BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>

                {filteredData.length > 0 ? (
                  <>
                    <Block>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <div>
                          <span>Filter by Status: </span>
                          <Select
                            style={{ width: 200 }}
                            onChange={handleStatusFilterChange}
                            value={statusFilter || "ALL"}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <div
                                  style={{
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>Search by Status: </span>
                                  <SearchOutlined />
                                </div>
                              </>
                            )}
                          >
                            <Option value="ALL">All</Option>
                            {/* {widgetData.map((widget, index) => (
                        <Option key={index} value={widget.title}>
                          {widget.title}
                        </Option>
                      ))} */}
                          </Select>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Input
                            placeholder="Search by Site Name &amp; RFQ ID"
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ width: 400 }}
                            prefix={<SearchOutlined />}
                          />
                        </div>
                      </div>
                    </Block>
                    <Row>
                      <Col>
                        <div
                          style={{ width: screenSize - 320, overflow: "auto" }}
                          id="customTable"
                        >
                          <Table
                            columns={columns}
                            dataSource={filteredData}
                            pagination={{
                              pageSize: 10,
                              showSizeChanger: true,
                              pageSizeOptions: ["10", "20", "30", "50"],
                            }}
                            bordered
                            size="middle"
                            scroll={{ x: 1400 }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Pending RFQ Approvals" />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Card>
        <AntModal
          title="Reject Budget"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical" name="rejectForm">
            <Form.Item
              name="reason"
              label="Reason for Rejection"
              rules={[{ required: true, message: "Please input your reason!" }]}
            >
              <Input.TextArea placeholder="Type your reason here..." />
            </Form.Item>
          </Form>
        </AntModal>
      </Content>
    </>
  );
};

export default RFQApprovals;
