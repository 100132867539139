import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function PurchaseBySupplier() {
  const navigate = useNavigate();
  const columns: any = [
    {
      key: "supplierId",
      title: "Supplier #",
      dataIndex: "supplierId",
      width: 100,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/purchase-by-supplier-detail-report/${text}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: "supplierName",
      title: "Supplier Name",
      width: 300,
      dataIndex: "supplierName",
    },
    {
      key: "totQuoteResponses",
      title: "Total Quote Responses",
      dataIndex: "totQuoteResponses",
    },
    {
      key: "avgResponseTime",
      title: "Average Response Time",
      dataIndex: "avgResponseTime",
    },
    {
      key: "totalAwards",
      title: "Total Awards",
      dataIndex: "totalAwards",
    },
    {
      key: "responseToAwardPercentage",
      title: "Response To Award Percentage",
      dataIndex: "responseToAwardPercentage",
      render:(text: any, record: any) =><span>{text}%</span>,
    },
    {
      key: "amountWithOutTax",
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      key: "amountWithTax",
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
   
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Supplier"
      moduleKey="purchaseBySupplier"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default PurchaseBySupplier;