import makeApiCall from "../master/axiosIndex";

const reportApis = {
  // purchase Reports Start ------------------------------
  purchaseOrdersReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseOrdersReport",
      data,
    });
  },
  purchaseBySupplierReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySupplierReport",
      data,
    });
  },
  purchaseBySupplierDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySupplierDetailReport",
      data,
    });
  },
  purchaseByMaterialReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByMaterialReport",
      data,
    });
  },
  purchaseByMaterialDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByMaterialDetailReport",
      data,
    });
  },

  purchaseByCategoryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByCategoryReport",
      data,
    });
  },
  orderDetailsByCategoryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/orderDetailsByCategoryReport",
      data,
    });
  },
  invoiceDetailsByCategoryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoiceDetailsByCategoryReport",
      data,
    });
  },

  purchaseBySiteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySiteReport",
      data,
    });
  },

  orderDetailsBySiteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/orderDetailsBySiteReport",
      data,
    });
  },
  invoiceDetailsBySiteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoiceDetailsBySiteReport",
      data,
    });
  },
  deliveryNoteDetailsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/deliveryNoteDetailsReport",
      data,
    });
  },

  materialsByDeliveryNoteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/materialsByDeliveryNoteReport",
      data,
    });
  },
  invoiceBySupplierReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoiceBySupplierReport",
      data,
    });
  },
  // purchase Reports End ------------------------------

  // Account Payable Reports Start ------------------------------
  supplierPaymentsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierPaymentsReport",
      data,
    });
  },
  supplierPaymentsInvoiceReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierPaymentsInvoiceReport",
      data,
    });
  },
  paymentsAgingSummaryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsAgingSummaryReport",
      data,
    });
  },
  paymentsAgingReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsAgingReport",
      data,
    });
  },
  // Account Payable Reports Start ------------------------------

  
};

export default reportApis;
