import React from "react";
import { useParams } from "react-router";
import InvoiceComponent from "../../invoice/InvoiceComponent";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";

function InvoicePreviewReport() {
  let { id }: any = useParams();
  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <InvoiceComponent
              invoiceNumber={id}
              previousPath="/invoice-by-supplier-reports-list"
              extra={{
                reduceTableWidth: 130,
              }}
            />
          </div>
        </Card>
      </Content>
    </>
  );
}

export default InvoicePreviewReport;
