import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function MaterialsByDeliveryNoteReport() {
  const { id } = useParams();
  const columns: any = [
    
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Material Quantity",
      dataIndex: "materialQuantity",
      key: "materialQuantity",
      render: (quantity: string,record:any) => <span>{quantity} {record.quantityUom}</span>,
    },
    {
      title: "Dispatched Quantity",
      dataIndex: "dispatchedQuantity",
      key: "dispatchedQuantity",
      render: (quantity: string,record:any) => <span>{quantity} {record.quantityUom}</span>,
    },
    {
      title: "GRN Quantity",
      dataIndex: "grnQuantity",
      key: "grnQuantity",
      render: (quantity: string,record:any) => <span>{quantity} {record.quantityUom}</span>,
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Materials By Delivery Note Report"
      moduleKey="materialsByDeliveryNoteReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/delivery-note-details"
      extra={{
        id: id,
        tableScrollX:1000
      }}
    />
  );
}

export default MaterialsByDeliveryNoteReport;