import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { Table } from "antd";

const dataSourceMap: any = {
  "/view-purchase-reports": {
    title: "Purchase Reports",
    dataSource: [
      {
        key: "1",
        reportName: "Purchase Order Report",
        reportType: "Purchase Order",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-order-reports-list",
      },
      {
        key: "2",
        reportName: "Purchase by Supplier Report",
        reportType: "Purchase by Supplier",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-supplier-reports-list",
      },
      {
        key: "3",
        reportName: "Purchase by Material Report",
        reportType: "Purchase by Material",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-material-reports-list",
      },
      {
        key: "4",
        reportName: "Purchase by Category Report",
        reportType: "Purchase by Category",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-category-reports-list",
      },
      {
        key: "5",
        reportName: "Purchase by Site Report",
        reportType: "Purchase by Site",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-site-reports-list",
      },
      {
        key: "6",
        reportName: "Invoice by Supplier Report",
        reportType: "Invoice by Supplier",
        createdBy: "System Generated",
        onClickNavigation: "/invoice-by-supplier-reports-list",
      },
      {
        key: "7",
        reportName: "Delivery Note Details",
        reportType: "Delivery Note Details",
        createdBy: "System Generated",
        onClickNavigation: "/delivery-note-details",
      },
    ],
  },
  "/view-accounts-payable-reports": {
    title: "Accounts Payable Reports",
    dataSource: [
      {
        key: "1",
        reportName: "Supplier Payments",
        reportType: "Supplier Payments",
        createdBy: "System Generated",
        onClickNavigation: "/supplier-payments-reports-list",
      },
      {
        key: "2",
        reportName: "Payment Aging Summary",
        reportType: "Payment Aging Summary",
        createdBy: "System Generated",
        onClickNavigation: "/payment-aging-summary-reports-list",
      },
      {
        key: "3",
        reportName: "Payment Aging",
        reportType: "Payment Aging",
        createdBy: "System Generated",
        onClickNavigation: "/payment-aging-reports-list",
      },
      {
        key: "4",
        reportName: "Payment Reports",
        reportType: "Payment Reports",
        createdBy: "System Generated",
        onClickNavigation: "/payment-reports-list",
      },
    ],
  },
};

function ViewAllReportsList() {
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const isActive = (path: string) => {
    return window.location.pathname === path ? "active" : "";
  };
  return (
    <>
      <Head title="View All Reports" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                      Modules
                    </h6>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li>
                      <Link
                        to="/view-purchase-reports"
                        className={isActive("/view-purchase-reports")}
                      >
                        <span>Purchase Reports</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/view-accounts-payable-reports"
                        className={isActive("/view-accounts-payable-reports")}
                      >
                        <span>Accounts Payable Reports</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* content start here */}
            <div className="card-inner card-inner-lg">
              {sm && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        {dataSourceMap[window.location.pathname].title}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div className="content clearfix">
                <div
                  style={{
                    width: screenSize - 350 + "px",
                    margin: "0px auto",
                  }}
                >
                  <Table
                    className="customTable"
                    columns={[
                      {
                        title: "Report Name",
                        dataIndex: "reportName",
                        key: "reportName",
                        render: (text, record:any) => (
                          <span
                            className="inlineTableCellLink" 
                            onClick={() => navigate(record?.onClickNavigation)}
                          >
                            {text}
                          </span>
                        ),
                      },
                      {
                        title: "Report Type",
                        dataIndex: "reportType",
                        key: "reportType",
                      },
                      {
                        title: "Created By",
                        dataIndex: "createdBy",
                        key: "createdBy",
                      },
                    ]}
                    dataSource={
                      dataSourceMap[window.location.pathname]?.dataSource
                    }
                    bordered
                    size="middle"
                    pagination={false}
                  />
                </div>
              </div>
            </div>

            {/* content end here */}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ViewAllReportsList;
