import React, { useEffect, useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormCheckbox from "../../form-components/CheckboxComponent";
import { useFormContext } from "react-hook-form";
import { getCountrys, getStatesByCountry } from "../../../api/misc/dropDowns";
import usersApis from "../../../api/master/users";
// import misc from "../../../api/misc/miscService";
// Define the structure of your form data
interface SelectOptions {
  label: string;
  value: string;
}
const ContactInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  }: any = useFormContext<FormData>();

  const submitForm = (data: FormData) => {
    props.next();
  };

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {
    fetchStatesByCountry(country);
  }, [country]);

  useEffect(() => {
    getDistricts(state);
  }, [state]);

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="contactPerson"
              label="Contact Person"
              required={true}
              // disabled={action === "view"}
              type="text"
              placeholder="Enter Contact Person"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="phoneNumber"
              label="Phone Number"
              // disabled={action === "view"}
              required={true}
              type="number"
              placeholder="Enter Phone Number"
              pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="email"
              label="Email"
              required={true}
              // disabled={action === "view"}
              type="email"
              maxLength={50}
              placeholder="Enter Email address"
              pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <FormSelect
              name="country"
              label="Country"
              disabled={true}
              options={countries}
              required={true}
              placeholder="-Select-"
              // onChange={(e) => {
              //   fetchStatesByCountry(e);
              // }}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="state"
              label="State"
              required={true}
              options={states}
              placeholder="Select State"
              disabled={true}
              // onChange={(ev) => onStateChange(ev)}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="district"
              label="District"
              options={districtOptions}
              required={true}
              disabled={true}
              placeholder="Select District"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <FormInput
              name="city"
              label="City"
              disabled={true}
              required={true}
              // disabled={action === "view"}
              placeholder="Enter City"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="area"
              disabled={true}
              // disabled={action === "view"}
              label="Area"
              required={true}
              placeholder="Enter Area"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="pincode"
              // disabled={action === "view"}
              disabled={true}
              label="Zip/Pincode"
              required={true}
              type="number"
              placeholder="Enter Zip or pin code"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <FormInput
              name="addressLine1"
              label="Address Line 1"
              disabled={true}
              required={true}
              placeholder="Enter Site Address"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="addressLine2"
              label="Address Line 2"
              // required={true}
              disabled={true}
              placeholder="Enter Address Line 2"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="applicationAccess"
              // disabled={action === "view"}
              label="Application Access"
              required={true}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Enter Application Access"
            />
          </Col>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default ContactInformation;
