import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Component";
import { Row, Col, Spinner } from "reactstrap";
import { useForm, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import usersApis from "../../../api/master/users";
import dayjs from "dayjs";
interface SelectOptions {
  label: string;
  value: string;
}
const LegalInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const [verifyingGstin, setVerifyingGstin] = useState(false);

  const FullFormDetails = watch();
  const gstinVerified = watch("gstinVerified");
  const gstin = watch("gstin");
  const cin = watch("cin");

  useEffect(() => {
    if (!gstin || !cin) return;
    if (
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
        gstin
      ) &&
      /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/.test(cin) 
      &&gstinVerified != "true"
    ) {
      setValue("gstinVerified", "false");
      setVerifyingGstin(true);
      validateSandbox({
        cin: cin,
        gstin: gstin,
        orgName: FullFormDetails.supplierName,
      });
    } else {
      setVerifyingGstin(false);
    }
  }, [gstin, cin]);

  const validateSandbox = async (data: any) => {
    try {
      const response = await supplierApis.verifyLegalDetails({
        cin: data.cin,
        gstin: data.gstin,
        orgName: data.orgName,
      });

      const sandboxResponse = response?.verificationResponse;
      const errorsArray: string[] = [];

      // Check if any GSTIN verification failed
      const gstinVerification = sandboxResponse.find(
        (obj: any) => obj.gstin && !obj.verified
      );

      if (gstinVerification) {
        // Show alert if GSTIN verification is false
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "GSTIN verification failed.",
        });
        setVerifyingGstin(false);
        return { errors: ["GSTIN verification failed."] };
      }

      // If verification passed, return the companyAddress
      const sandBoxObj= sandboxResponse.find(
        (obj: any) => obj.gstin && obj.verified
      )

      const {companyAddress, dateOfRegistration}=sandBoxObj 

      if (!gstinVerification) {

        if (companyAddress) {
          setValue("addressLine1", companyAddress?.bno);
          setValue("addressLine2", companyAddress?.landMark);
          setValue("country", "1");
          await fetchStatesByCountry(
            "1",
            companyAddress?.stcd,
            companyAddress?.dst.toLowerCase().replace(/\s+/g, "")
          );

          setValue(
            "area",
            companyAddress?.locality + ", " + companyAddress?.landMark
          );

          setValue("city", companyAddress?.loc);
          setValue("pincode", companyAddress?.pncd);
        }
        setValue("pan", data.gstin.substring(2, 12)); // Extract substring instead of using splice
        setValue("gstinVerified", "true");
      }
      setValue(
        "incorporationDate",
        dayjs(dateOfRegistration,"DD/MM/YYYY")
      );
      setVerifyingGstin(false);
      return { errors: errorsArray, companyAddress };
    } catch (error: any) {
      setVerifyingGstin(false);
      console.log(error.message);
      throw error;
    }
  };

  const fetchStatesByCountry = async (
    countryId: string,
    stateName: string,
    districtName: string
  ) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));

      if (stateName) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.label === stateName
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
        await getDistricts(state?.value, districtName);
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any, districtName: string) => {
    try {
      const response = await usersApis.getDistrictsByState(stateID);
      const { data, status } = response;
      if (status) {
        if (data.length > 0) {
          const district = data.find((item: any) => {
            return (
              item.districtName.toLowerCase().replace(/\s+/g, "") ==
              districtName
            );
          });
          if (district) {
            setValue("district", district.districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <>
      <form
        className="content clearfix"
        onSubmit={handleSubmit(() => {
          props.next();
        })}
      >
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="cin"
              label="CIN"
              disabled={gstinVerified == "true"}
              required={true}
              type="text"
              placeholder="Enter CIN"
              pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="gstin"
              label="GSTIN"
              disabled={gstinVerified == "true"}
              required={true}
              type="text"
              placeholder="Enter GSTIN"
              pattern={
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
              }
            />
          </Col>

          {verifyingGstin && (
            <Col lg="4" className="d-flex align-items-end">
              <Spinner size="sm" color="primary" className="m-2" />
            </Col>
          )}
          <span style={{ display: "none" }}>
            <FormInput name="gstinVerified" label="" />
          </span>
          {gstinVerified && !verifyingGstin && (
            <>
              <Col lg="4">
                <FormInput
                  name="pan"
                  label="PAN"
                  disabled
                  required={true}
                  type="text"
                  placeholder="Enter PAN"
                  pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                />
              </Col>
              <Col lg="4">
                <FormDatePicker
                  name="incorporationDate"
                  label="Incorporation Date"
                  disabled
                  required={true}
                  // minDate={new Date()} // Today's date as the minimum date
                  maxDate={new Date()} // Maximum date set to one year from today
                />
              </Col>
              <Col lg="4">
                <FormInput
                  name="lastFYTurnover"
                  label="Last FY Turn Over (INR)"
                  type="number"
                  placeholder="Enter Last FY Turn over"
                />
              </Col>
            </>
          )}
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button
                color="primary"
                type="submit"
                disabled={gstinVerified != "true" || verifyingGstin}
              >
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default LegalInformation;
