import React, { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  DataTable,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../components/Component";
import UserProfileAside from "./PurchaseRequestAside";
import { DoubleBar } from "../../components/partials/charts/default/Charts";
import sites from "../../api/master/sites";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "../purchase-orders/PurchaseOrderAside";
import { Empty, Flex, Input, Select, Spin, Table } from "antd";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import { ColumnGroupType, ColumnType } from "antd/es/table";
import moment from "moment";
import PurchaseRequestAside from "./PurchaseRequestAside";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import dayjs from "dayjs";

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}


// const orderStatusCards: any = {
//   // draft: {
//   //   color: "#e85347",
//   //   lable: "Draft Purchase Order",
//   // },

//   pending: {
//     color: "#09c2de",
//     lable: "Pending Approvals",
//   },
//   withSuppliers: {
//     color: "#364a63",
//     lable: "With Suppliers",
//   },
//   invoiced: {
//     color: "#1ee0ac",
//     lable: "Invoiced",
//   },
//   deliveredGrn: {
//     color: "#2263b3",
//     lable: "Delivered GRN",
//   },
//   shortClosed: {
//     color: "rgb(249, 140, 69)",
//     lable: "Short Closed",
//   },
// };

const ViewPurchaseRequests: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [editLineRow, setEditLineRow] = useState<any>();
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);
    fetchList();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchList = async () => {
    try {
      const { response, status, message } = await purchaseRequestApis.fetchList();
      if (status) {
        const data = response;
        console.log("data", data);
        setTableData(data);
        setFilteredData(data);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let filteredData = tableData;

    if (searchText) {
      filteredData = filteredData.filter(
        (item) =>
          item.rfqId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.quotationDescription
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      filteredData = filteredData.filter((item) => item.status === status);
    }

    setFilteredData(filteredData);
  };
  const navigate = useNavigate();


  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    // navigate("/rfq-full-view", { state: { rfq } });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "PR #",
      dataIndex: "prSequence",
      key: "prSequence",
      fixed: "left",
      width: 200,
      sorter: (a: any, b: any) => a.prSequence.localeCompare(b.prSequence),
      align: "left"
    },
    {
      title: "Indent Number",
      dataIndex: "referenceIndentNumber",
      key: "referenceIndentNumber",
      // fixed: "left",
      width: 200,
      sorter: (a: any, b: any) => a.referenceIndentNumber.localeCompare(b.referenceIndentNumber),
      align: "left",
      // render: (text, record) => (
      //   <a onClick={() => handleRFQClick(record)} style={{color:'#1677ff',fontWeight:"600"}}>{text}</a>
      // ),
    },
    {
      title: "Indent Date",
      dataIndex: "indentDate",
      key: "indentDate",
      // fixed: "left",
      width: 200,
      // sorter: (a, b) =>
      //   a.quotationDescription.localeCompare(b.quotationDescription),
      // align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
      sorter: (a, b) => a.siteName.localeCompare(b.siteName),
      align: "left",
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Requested By",
      dataIndex: "requestByName",
      key: "requestByName",
      width: 200,
      sorter: (a: any, b: any) => a.requestByName.localeCompare(b.requestByName),
      align: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "lastDateOfSubmission",
      key: "lastDateOfSubmission",
      width: 200,
      sorter: (a, b) =>
        new Date(a.lastSubmissionDate).getTime() -
        new Date(b.lastSubmissionDate).getTime(),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Delivery Location",
      dataIndex: "deliveryAddressName",
      key: "deliveryAddressName",
      width: 150,
      sorter: (a: any, b: any) => a.deliveryAddressName.localeCompare(b.deliveryAddressName),
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Created By",
      dataIndex: "createdUser",
      key: "createdUser",
      width: 150,
      align: "left",
    },
    {
      title: "Created Date",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 150,
      align: "left",
      render: (date) => <span>{date?FormatDate(date):''}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      align: "left",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (text, record: any, index) =>
        <>
          <Button
            size="sm"
            // color=""
            onClick={() => {
              navigate(`/view_pr/${record.prId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
    },
  ];

  return (
    <>
      <Head title="View Purchase Requests - List" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <PurchaseRequestAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">Purchase Requests</BlockTitle>
                      {/* <BlockDes>
                      <p>Approval/Reject Purchase Request</p>
                    </BlockDes> */}
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {
                  filteredData.length > 0 ? (
                    <>
                      <Block>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <div>
                            <span>Filter by Status: </span>
                            <Select
                              style={{ width: 200 }}
                              onChange={handleStatusFilterChange}
                              value={statusFilter || "ALL"}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <div
                                    style={{
                                      padding: "8px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>Search by Status: </span>
                                    <SearchOutlined />
                                  </div>
                                </>
                              )}
                            >
                              {/* <Option value="ALL">All</Option>
                      {widgetData.map((widget, index) => (
                        <Option key={index} value={widget.title}>
                          {widget.title}
                        </Option>
                      ))} */}
                            </Select>
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Input
                              placeholder="Search by Site Name &amp; RFQ ID"
                              value={searchText}
                              onChange={handleSearchChange}
                              style={{ width: 400 }}
                              prefix={<SearchOutlined />}
                            />
                          </div>
                        </div>
                      </Block>
                      <Row>
                        <Col>
                          <div
                            style={{ width: screenSize - 320, overflow: "auto" }}
                            id="customTable"
                          >
                            <Table
                              columns={columns}
                              dataSource={filteredData}
                              pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "30", "50"],
                              }}
                              bordered
                              size="middle"
                              scroll={{ x: 1400 }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <Empty description="No Purchase Requests" />
                      </div>
                    </>
                  )
                }
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ViewPurchaseRequests;
