import React, { useEffect, useState } from "react";
import { Table, Avatar, Select, Empty, Drawer } from "antd"; // Added Empty import
import { formatCurrency2, getUserLabel } from "../../utils/Utils"; // Make sure the import path is correct
import supplierApis from "../../api/master/supplier";
import mainDashboardApis from "../../api/dashboard/MainDashboard";

const { Option } = Select;

interface SupplierSavingsData {
  supplierID: string;
  supplierName: string;
  quoted: number;
  percentageOfSavings: number;
  category: string;
}

const TopSupplierSavingsWidget: React.FC<any> = ({ dates, sites }) => {
  const [data, setTopSupplierSavings] = useState<SupplierSavingsData[]>([]);
  const [allRecords, setAllRecords] = useState<SupplierSavingsData[]>([]);
  const [topSupplierSavingsDrawer, setTopSupplierSavingsDrawer] =
    useState(false);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  const onClose = () => {
    setTopSupplierSavingsDrawer(false);
  };

  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          console.log("data", item);
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };

  useEffect(() => {
    if (sites && category) {
      fetchTopSupplierSavings();
    }
  }, [sites, category, dates]);

  const fetchTopSupplierSavings = async () => {
    try {
      const { from, to } = dates;
      const formData = {
        siteId: sites,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await mainDashboardApis.topSupplierSavings(formData);
      const { response, status } = res;
      console.log(response);
      if (status) {
        setAllRecords(response);
        setTopSupplierSavings(response.slice(0, 5));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Filtered data based on the selected category
  const filteredData = data;

  // Define the columns for the table
  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      render: (text: string) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{
              backgroundColor: "#E5E9F2",
              color: "#526484",
              marginRight: 8,
              height: 40,
              width: 40,
              fontWeight: "bold",
            }}
          >
            {getUserLabel(text)}
          </Avatar>
          {text}
        </div>
      ),
    },
    {
      title: "# Quoted",
      dataIndex: "quoted",
      key: "quoted",
    },
    {
      title: "Avg. Savings (%)",
      dataIndex: "percentageOfSavings",
      key: "percentageOfSavings",
      render: (text: number, record: any) =>
        `${formatCurrency2(record.costsaving, "INR")} (${text}%)`, // Append percentage symbol
    },
  ];

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Top Supplier Savings</h6>
          <p>
            Insights on savings by suppliers.
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setTopSupplierSavingsDrawer(true);
              }}
            >
              View All
            </a>
          </p>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}> Filter by Category: </span>
          <Select
            defaultValue="ALL"
            style={{ width: 150 }}
            onChange={handleCategoryChange}
          >
            {categoryOptions?.map((item: any) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="supplierID"
          pagination={false}
          size="small"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "" : "striped-row"
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No Data Available for the Selected Category" />
        </div>
      )}
      {
        <Drawer
          title="Top Supplier Savings"
          placement="right"
          width={"40vw"}
          onClose={onClose}
          open={topSupplierSavingsDrawer}
        >
          <Table
            columns={columns}
            dataSource={allRecords}
            rowKey="id"
            size="small"
            pagination={false}
          />
        </Drawer>
      }
    </div>
  );
};

export default TopSupplierSavingsWidget;
