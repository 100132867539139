import { Drawer } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import { useEffect } from "react";

interface SettingsFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  prId: any;
  initialValues: any;
  settingFormData?: any; // New prop for current document data
}

const SettingsForm: React.FC<SettingsFormProps> = ({
  open,
  onClose,
  prId,
  initialValues,
  onSubmit,
}) => {
  const methods = useForm<any>({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  useEffect(() => {
    if (initialValues) {
      reset(initialValues); // Reset form with initialValues when they change
    }
  }, [initialValues, reset]);

  const submitSettingsData = (values: any) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Drawer
      title="Default Settings"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitSettingsData)}>
          <Row>
            <Col lg="12">
              <FormSelect
                name="deadlineType"
                label="Submission Type"
                defaultValue={initialValues.deadlineType}
                options={[
                  { label: "Soft", value: "SOFT" },
                  { label: "Hard", value: "HARD" },
                ]}
                required={true}
                placeholder="Select"
              />
            </Col>
            <Col lg="12">
              <FormSelect
                name="additionalCharges"
                label="Additional Charges?"
                defaultValue={initialValues.additionalCharges}
                options={[
                  { label: "Fixed", value: "Fixed" },
                  { label: "Flexible", value: "Flexible" },
                ]}
                required={true}
                placeholder="Select"
              />
            </Col>
            <Col lg="12">
              <FormSelect
                name="autoRelease"
                label="Auto Release"
                defaultValue={initialValues.autoRelease}
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
                required={true}
                placeholder="Select"
              />
            </Col>
            <Col lg="12">
              <FormSelect
                name="deliveryAddressType"
                label="Delivery Address Type"
                defaultValue={initialValues.deliveryAddressType}
                options={[
                  { label: "Order Level", value: "ORDER_LEVEL" },
                  { label: "Line Level", value: "LINE_LEVEL" },
                ]}
                required={true}
                placeholder="-Select-"
                // tooltipText="Select the delivery address type for the Quotation."
                disabled={prId ? true : false}
              />
            </Col>
            <Col lg="12">
              <FormSelect
                name="needByDateType"
                label="Multiple Delivery Dates ?"
                defaultValue={initialValues.needByDateType}
                options={[
                  { label: "Yes", value: "LINE_LEVEL" },
                  { label: "No", value: "ORDER_LEVEL" },
                ]}
                placeholder="-Select-"
                // tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                required={true}
              />
            </Col>
          </Row>
          <Button type="submit" color="primary" className="mt-3">
            Save
          </Button>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default SettingsForm;
