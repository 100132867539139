import React from "react";
import { useNavigate } from "react-router";
import DefaultReportTemplate from "../defaultReportTemplate";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";

function DeliveryNoteDetailsReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Delivery Note Details Report",
      dataIndex: "deliveryNoteId",
      key: "deliveryNoteId",
      width: 200,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/materials-by-delivery-note-report/${text}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 100,
      render: (date: any) => <span>{FormatDate(date) || "--"}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      width: 200,
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNumber",
      key: "vehicleNumber",
      width: 130,
    },
    {
      title: "Invoice Id",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
    },
    // {
    //   title: "Supplier ID",
    //   dataIndex: "supplierId",
    //   key: "supplierId",
    // },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`desc-tooltip-${index}`}>
              {text.length > 20 ? `${text!.substring(0, 25)}... ` : text}
            </span>
            {text.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`desc-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    // {
    //   title: "Currency Code",
    //   dataIndex: "currencyCode",
    //   key: "currencyCode",
    // },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Delivery Note Details Report"
      moduleKey="deliveryNoteDetailsReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        supplier: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX: 1200,
      }}
    />
  );
}

export default DeliveryNoteDetailsReport;
