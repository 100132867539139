import React, { useEffect, useState } from "react";
import { Divider, message, Modal, Space, Upload, UploadFile } from "antd";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, Col, Row, Spinner } from "reactstrap";
import miscService from "../../api/misc/misc";
import FormInput from "../form-components/InputComponent";
import FormMultiSelect from "../form-components/MultiSelectComponent";
import FormSelect from "../form-components/SelectComponent";
import { PaperClipOutlined } from "@ant-design/icons";
const { Dragger } = Upload;
function EmailCreationModal({
  body,
  modal,
  toggle,
  sendEmail,
  defaultAttachments,
  suppliers,
  orgDetails,
}: any) {
  const [usersList, setUsers] = useState([]);
  const [submitBtnClick, setSubmitBtnClick] = useState(false);

  const [toList, setToList] = useState(
    usersList?.map((user: any) => ({
      label: user.name + " ( " + user.email + " )",
      value: user.email,
    }))
  );
  const [ccList, setCcList] = useState(
    usersList?.map((user: any) => ({
      label: user.name + " ( " + user.email + " )",
      value: user.email,
    }))
  );
  const [bccList, setBccList] = useState(
    usersList?.map((user: any) => ({
      label: user.name + " ( " + user.email + " )",
      value: user.email,
    }))
  );

  const [addUserModal, setAddUserModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    // reset,
    watch,
    formState: { errors },
    // setValue,
  } = methods;

  const [selectedSupplierName, setSelectedSupplierName] = useState("");

  const emailWatch = watch("email");

  const ToWatch = watch("to");
  const CcWatch = watch("cc");
  const BccWatch = watch("bcc");
  const supplierIdWatch = watch("supplierId");

  useEffect(() => {
    if (supplierIdWatch) {
      const name: any = suppliers?.find((item: any) => {
        return item.supplierID == supplierIdWatch;
      })?.supplierName;
      setSelectedSupplierName(name);
    }
  }, [supplierIdWatch]);

  useEffect(() => {
    if (ToWatch || CcWatch || BccWatch || usersList) {
      setToList(
        usersList
          ?.filter(
            (selectedOption: any) =>
              ![...(CcWatch || []), ...(BccWatch || [])]?.includes(
                selectedOption?.email
              )
          )
          ?.map((item: any) => ({
            label: item.name + " ( " + item.email + " )",
            value: item.email,
          }))
      );
      setBccList(
        usersList
          ?.filter(
            (selectedOption: any) =>
              ![...(ToWatch || []), ...(CcWatch || [])]?.includes(
                selectedOption?.email
              )
          )
          ?.map((item: any) => ({
            label: item.name + " ( " + item.email + " )",
            value: item.email,
          }))
      );
      setCcList(
        usersList
          ?.filter(
            (selectedOption: any) =>
              ![...(ToWatch || []), ...(BccWatch || [])]?.includes(
                selectedOption?.email
              )
          )
          ?.map((item: any) => ({
            label: item.name + " ( " + item.email + " )",
            value: item.email,
          }))
      );
    }
  }, [ToWatch, CcWatch, BccWatch, usersList]);

  const fetchUsers = async () => {
    try {
      const response = await miscService.fetchEmailConfigurations();
      const { data, status } = response;
      if (status) {
        setUsers(
          data?.map((item: any) => ({
            name: item?.name,
            email: item?.email,
            id: item?.id,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addUser = async (_data: any) => {
    try {
      const data = await miscService.createEmailConfigurations(_data);
      if (data) {
        fetchUsers();
      }
    } catch (error) {
      console.log(error);
    }
    setAddUserModal(false);
  };

  useEffect(() => {
    try {
      fetchUsers();
    } catch (error) {}
  }, []);

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type || "");
    if (!isAllowedType) {
      message.error(
        "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
      );
      return Upload.LIST_IGNORE;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = (info: { fileList: UploadFile[] }) => {
    setFileList(info.fileList);
  };

  return (
    <Modal
      title={addUserModal ? "Add User" : "Create Email"}
      open={modal}
      footer={null}
      onCancel={() => {
        if (addUserModal) {
          setAddUserModal(false);
        } else {
          toggle();
        }
      }}
      width="60%"
    >
      {addUserModal ? (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((data) => {
              methods.reset();
              addUser(data);
            })}
          >
            <Row>
              <Col md={6}>
                <FormInput
                  name="name"
                  required={true}
                  label={"Name"}
                  placeholder="Enter name"
                />
              </Col>
              <Col md={6}>
                <FormInput
                  name="email"
                  required={true}
                  label={"Email"}
                  placeholder="Enter email address"
                  pattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                />
                {usersList
                  ?.map((item: any) => item?.email)
                  ?.includes(emailWatch) && (
                  <span className="text-danger">Email already exists</span>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {usersList
                    ?.map((item: any) => item?.email)
                    ?.includes(emailWatch) ? (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        color="primary"
                        disabled
                      >
                        Save
                      </Button>
                    </li>
                  ) : (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setAddUserModal(false);
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((data) => {
              setSubmitBtnClick(true);
              sendEmail({
                ...data,
                attachments: [defaultAttachments, ...fileList],
              },methods);
              // methods.reset();
            })}
          >
            <Row className="mt-12">
              <FormSelect
                name="supplierId"
                required={true}
                optionLabelWidth={"60px"}
                label={undefined}
                optionLabel="Supplier"
                options={suppliers?.map((item: any) => ({
                  label: item?.supplierName,
                  value: Number(item?.supplierID),
                }))}
                placeholder=""
                dropdownRender={(menu) => <>{menu}</>}
              />
              <FormMultiSelect
                name="to"
                required={true}
                optionLabelWidth={"60px"}
                label={undefined}
                optionLabel="To "
                options={toList}
                placeholder=""
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        className="btn btn-primary btn-md"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setAddUserModal(true);
                        }}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <span>Add Mail</span>
                      </Button>
                    </Space>
                  </>
                )}
              />

              <FormMultiSelect
                name="cc"
                label={undefined}
                optionLabel="CC "
                options={ccList}
                optionLabelWidth={"60px"}
                placeholder=""
                dropdownRender={(menu) => <>{menu}</>}
              />

              {/* <FormMultiSelect
                name="bcc"
                label={undefined}
                optionLabelWidth={"60px"}
                optionLabel="BCC"
                options={bccList}
                placeholder=""
                dropdownRender={(menu) => <>{menu}</>}
              /> */}
            </Row>

            <Row>
              <strong style={{ textAlign: "right" }}>Email Preview</strong>
              <div className="w-100 p-2">
                <img
                  src={orgDetails?.orgLogo}
                  alt={orgDetails?.orgName}
                  width="200"
                  style={{ marginTop: "20px" }}
                />
                <div style={{ backgroundColor: "#fff", padding: "15px" }}>
                  <h2 style={{ color: "#2263b3" }}>Request for Quotation</h2>
                  <p style={{ color: "#1ee0ac", fontSize: "20px" }}>
                    Dear {selectedSupplierName},
                  </p>

                  {body}
                </div>
              </div>
            </Row>

            <Row>
              <strong>Attachments:</strong>

              <Col md={12} className="">
                <Controller
                  name="attachments"
                  control={methods.control}
                  render={({ field }) => (
                    <Dragger
                      beforeUpload={beforeUpload}
                      fileList={fileList}
                      onChange={handleChange}
                      className="ant-upload-drag-custom"
                    >
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  )}
                />
                <div key={0} className="w-100 d-flex justify-content-between">
                  <div>
                    <PaperClipOutlined />
                    &nbsp; {defaultAttachments?.name} &nbsp; &nbsp; &nbsp;
                  </div>
                  <div>&nbsp; &nbsp;</div>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    {submitBtnClick ? (
                      <Button color="primary" type="button">
                        <Spinner size="sm" color="light" />
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-primary btn-md"
                        color="primary"
                      >
                        Send
                      </Button>
                    )}
                  </li>
                  {!submitBtnClick && (
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
}

export default EmailCreationModal;
