import React, { useEffect, useState } from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { TooltipComponent } from "../../components/Component";
import { Select } from "antd";

interface SelectOption {
  value: any;
  label: string;
}

interface FormMultiSelectProps {
  name: string;
  label: any;
  optionLabel?: string;
  optionLabelWidth?: any;
  options: SelectOption[];
  required?: any;
  placeholder?: string;
  customValidation?: (value: string[]) => boolean | string;
  maxLength?: number;
  defaultValue?: (string | Number)[];
  disabled?: boolean;
  onChange?: (value: string[]) => void;
  dropdownRender?: (props: any) => JSX.Element;
  tooltipText?: string;
  popOverOptions?: any;
  onBlur?: (event: React.FocusEvent<HTMLElement>, value?: any) => void;
}

const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name,
  label,
  optionLabel,
  optionLabelWidth="34px",
  options,
  required = false,
  placeholder = "-Select-",
  customValidation,
  maxLength,
  defaultValue,
  disabled,
  onChange,
  tooltipText,
  popOverOptions,
  dropdownRender,
  onBlur,
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const [selectedValues, setSelectedValues] = useState<string[]>([]); // Add this line

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `Maximum length is ${maxLength} characters`,
          }
        : undefined,
      validate: customValidation
        ? (value: string[]) => customValidation(value) || "Invalid value"
        : undefined,
    });
  }, [register, name, required, maxLength, customValidation]);

  const errorMessage = getErrorMessage(errors, name);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedValues(selectedOption);
    // console.log("Selected options:", selectedOption);

    if (onChange) {
      onChange(selectedOption);
    }

    // Trigger custom onBlur logic after deselect
    if (onBlur) {
      onBlur(null as any, selectedOption); // Trigger onBlur manually with selected values
    }
  };

  return (
    <div className={`form-group ${optionLabel?"mb-1":""}`}>
      {label && <div className="form-label-wrapper">
        <label className="form-label" htmlFor={name}>
          {label}
          {required && <span className="mandatory">*</span>}
        </label>
        {popOverOptions}
        {tooltipText && (
          <TooltipComponent
            iconClass="card-hint"
            icon="help-fill"
            direction="left"
            id={`${name}-tooltip`}
            text={tooltipText}
            containerClassName={""}
          />
        )}
      </div>}
      <div className="form-control-wrap">
        <div className={`form-control-select ${optionLabel?'d-flex align-items-center':""}`}>
          {optionLabel&&<label className={`form-label`} style={{minWidth:optionLabelWidth}} htmlFor={name}>
            {optionLabel}
          </label>}
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                mode="multiple"
                options={options}
                maxTagCount="responsive"
                value={field.value || []}
                onChange={(selectedOption: any) => {
                  field.onChange(selectedOption);
                  setSelectedValues(selectedOption);
                  handleSelectChange(selectedOption);
                }}
                onBlur={(event: any) => {
                  if (onBlur) {
                    onBlur(event, field.value); // Pass the event to onBlur
                  }
                }}
                style={{ width: "100%", minHeight: "38px" }}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                dropdownRender={dropdownRender?dropdownRender:(menu) => (
                  <>
                    {field?.value?.length === options?.length ? (
                      <>
                        <a
                          className="addNewItem"
                          onClick={() => {
                            field.onChange([]); // Clear all options
                            setSelectedValues([]); // <-- Ensure state is cleared here
                          }}
                        >
                          Clear All
                        </a>
                        <hr />
                      </>
                    ) : (
                      <>
                        {options?.length > 6 && <a
                          className="addNewItem"
                          onClick={() => {
                            const allValues = options?.map(
                              (obj: any) => obj.value
                            );
                            field.onChange(allValues); // Select all options
                            setSelectedValues(allValues); // <-- Ensure state is updated here
                          }}
                        >
                          Select All
                        </a>}
                        <hr />
                      </>
                    )}
                    <div className="drop-down-multiselect">{menu}</div>
                  </>
                )}
              />
            )}
          />
          {errorMessage&&!optionLabel && <p className="mandatory">{errorMessage}</p>}
        </div>
          {errorMessage&&optionLabel && <p className="mandatory">{errorMessage}</p>}
      </div>
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean);
  let currentError: any = errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormMultiSelect;
