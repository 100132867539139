import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";

function SupplierPaymentsReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 200,
    },

    {
      title: "Invoiced Amount",
      dataIndex: "invoicedAmount",
      key: "invoicedAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount In Process",
      dataIndex: "amountInProcess",
      key: "amountInProcess",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Amount To Be Paid",
      dataIndex: "payableAmount",
      key: "payableAmount",

      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/supplier-payments-invoice-report/${record?.supplierId}`);
            }}
          >
            {text}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Supplier Payments Report"
      moduleKey="supplierPaymentsReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default SupplierPaymentsReport;
