import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

function PurchaseBySupplierDetailReport() {
  const { id } = useParams();
  const columns: any = [
    {
      key: "orderId",
      title: "Order ID",
      dataIndex: "orderId",
      // width:200,
      fixed: "left",
    },
    {
      key: "orderDate",
      title: "Order Date",
      dataIndex: "orderDate",
      // width:100,
      render: (text: any, record: any) => (
        <div>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </div>
      ),
    },
    {
      key: "materialName",
      title: "Material Name",
      dataIndex: "materialName",
    },
    {
      key: "unitPrice",
      title: "Unit Price",
      dataIndex: "unitPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      key: "amountWithOutTax",
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      key: "amountWithTax",
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{text}</span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Supplier Detail Report"
      moduleKey="purchaseBySupplierDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-supplier-reports-list"
      extra={{
        id:id
      }}
    />
  );
}

export default PurchaseBySupplierDetailReport;
